import React from "react";
import {
    Datagrid,
    TextField,
    ShowButton,
    EditButton,
    ReferenceManyField ,
    SingleFieldList,
} from 'react-admin';
import ResourceIdField from "../../../Field/Common/ResourceIdField";
import UserTypeField from "../../../Field/User/UserTypeField";
import UserActiveStatusField from "../../../Field/User/UserActiveStatusField";
import PhoneField from "../../../Field/User/PhoneField";
import UserSubscriptionStatusField from "../../../Field/User/UserSubscriptionStatusField";
import CreatedAtField from "../../../Field/Common/CreatedAtField";
import UpdatedAtField from "../../../Field/Common/UpdatedAtField";
import useStyles from "../../../../styles";
import ListConfirmDelete from "../../../Element/List/ListConfirmDelete";
import RelatedUserField from "../../../Field/User/RelatedUserField";

export const UsersList = (props) => {
    const classes = useStyles();
    return (
        <ListConfirmDelete {...props}>
            <Datagrid>
                <ResourceIdField cellClassName={classes.narrow}/>
                <CreatedAtField cellClassName={classes.narrow}/>
                <UpdatedAtField cellClassName={classes.narrow}/>
                <TextField source="name" cellClassName={classes.narrow}/>
                <PhoneField cellClassName={classes.narrow}/>
                <ReferenceManyField source="relatedUsers" reference="users" target="related_user_id">
                    <SingleFieldList>
                        <RelatedUserField cellClassName={classes.narrow} removeLabel />
                    </SingleFieldList>
                </ReferenceManyField >
                <UserTypeField cellClassName={classes.narrow}/>
                <UserSubscriptionStatusField cellClassName={classes.narrow}/>
                <ShowButton cellClassName={classes.narrow}/>
                <EditButton cellClassName={classes.narrow}/>
            </Datagrid>
        </ListConfirmDelete>
    );
};
