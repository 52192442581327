import * as React from "react";
import {
    BooleanField,
    NumberField,
    ReferenceField,
    RichTextField,
    Show,
    SimpleShowLayout,
} from 'react-admin';
import CreatedAtField from "../../../Field/Common/CreatedAtField";
import UpdatedAtField from "../../../Field/Common/UpdatedAtField";

export const LessonQuestionAnswerShow = (props) => (
    <Show {...props}>
        <SimpleShowLayout>
            <ReferenceField label="Lesson question" source="lessonQuestion" reference="lesson-questions">
                <RichTextField source="question" />
            </ReferenceField>
            <RichTextField source="text" />
            <BooleanField source="correct" />
            <NumberField source="position" />
            <CreatedAtField addLabel />
            <UpdatedAtField addLabel />
        </SimpleShowLayout>
    </Show>
);
