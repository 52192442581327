import React from "react";
import { PhoneChip } from "../../Element/Chip/PhoneChip";
import { FieldProps } from "../../../core/Types/common";

const PhoneField = (props: FieldProps) => {
    const { record, source } = props;
    return <PhoneChip phone={record[source]} />
}

PhoneField.defaultProps = { label: 'Phone', source: 'phone' }

export default PhoneField;
