import React from "react";
import { FieldProps } from "../../../core/Types/common";
import { RadioButtonGroupInput } from 'react-admin';

interface Props extends FieldProps {
    options?: object
    row?: boolean
    translateChoice?: boolean
}

export interface ChoiceInterface {
    id: string | number
    name: string
    selected?: boolean,
}

export class EnumTypeChoiceInput extends React.Component<Props>
{
    render() {
        const choices = this.formatChoices();

        return <RadioButtonGroupInput choices={choices} {...this.props}/>
    }

    formatChoices() {
        return this.getChoices().map(({ id, name }) => ({ id, name }));
    }

    getChoices(): ChoiceInterface[] {
        return [];
    }
}
