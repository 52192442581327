export const WALLET_TRANSACTION_TYPE_INTERNAL = 1;
export const WALLET_TRANSACTION_TYPE_REQUEST = 2;
export const WALLET_TRANSACTION_TYPE_INTEREST = 3;
export const WALLET_TRANSACTION_TYPE_POCKET_MONEY = 4;
export const WALLET_TRANSACTION_TYPE_GOAL = 5;
export const WALLET_TRANSACTION_TYPE_DEPOSIT = 6;

export const WALLET_TRANSACTION_TYPE_LEVEL_REWARD = 7;
export const WALLET_TRANSACTION_TYPE_INSUFFICIENT_FUNDS = 8;
export const WALLET_TRANSACTION_TYPE_WITHDRAW_ATM = 9;
export const WALLET_TRANSACTION_TYPE_TOP_UP = 10;
export const WALLET_TRANSACTION_TYPE_TRANSACTION_FEE = 11;
export const WALLET_TRANSACTION_TYPE_WITHDRAW_IBAN = 12;

export const IDLE_WALLET_SAVINGS = 1;
export const IDLE_WALLET_INVESTMENTS = 2;

export const BANKING_TRANSACTION_TYPE_DEPOSIT = 1;
export const BANKING_TRANSACTION_TYPE_WITHDRAWAL = 2;
