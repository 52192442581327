import React, {useState} from 'react';
import {Button, FormWithRedirect, SaveButton, useCreate, useNotify} from 'react-admin';
import IconContentAdd from '@material-ui/icons/Add';
import IconCancel from '@material-ui/icons/Cancel';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';

function QuickCreateButton({onChange, resource, label, dialogContent}) {
    const [showDialog, setShowDialog] = useState(false);
    const [create, {loading}] = useCreate(resource);
    const notify = useNotify();

    const handleClick = () => {
        setShowDialog(true);
    };

    const handleCloseClick = () => {
        setShowDialog(false);
    };

    const handleSubmit = async values => {
        create(
            {payload: {data: values}},
            {
                onSuccess: ({data}) => {
                    setShowDialog(false);
                    onChange();
                },
                onFailure: ({error}) => {
                    notify(error.message, 'error');
                }
            }
        );
    };

    return (
        <>
            <Button onClick={handleClick} label="ra.action.create">
                <IconContentAdd/>
            </Button>
            <Dialog
                fullWidth
                open={showDialog}
                onClose={handleCloseClick}
                aria-label={label}
            >
                <DialogTitle>{label}</DialogTitle>

                <FormWithRedirect
                    resource="resource"
                    save={handleSubmit}
                    render={({
                                 handleSubmitWithRedirect,
                                 pristine,
                                 saving
                             }) => (
                        <>
                            {dialogContent}
                            <DialogActions>
                                <Button
                                    label="ra.action.cancel"
                                    onClick={handleCloseClick}
                                    disabled={loading}
                                >
                                    <IconCancel/>
                                </Button>
                                <SaveButton
                                    handleSubmitWithRedirect={
                                        handleSubmitWithRedirect
                                    }
                                    pristine={pristine}
                                    saving={saving}
                                    disabled={loading}
                                />
                            </DialogActions>
                        </>
                    )}
                />
            </Dialog>
        </>
    );
}

export default QuickCreateButton;
