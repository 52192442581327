import React from "react";
import {
    BooleanInput,
    Edit,
    NumberInput,
    SimpleForm,
    TextField,
    TextInput,
    required
} from 'react-admin';
import UpdatedAtField from "../../../Field/Common/UpdatedAtField";
import CreatedAtField from "../../../Field/Common/CreatedAtField";
import LastLoginField from "../../../Field/Common/LastLoginField";
import TimestampField from "../../../Field/Common/TimestampField";

export const UserEdit = (props) => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput source="name" validate={required()} />
            <TextInput source="phone" validate={required()} />
            <BooleanInput source="isActive"/>
            <BooleanInput source="subscriptionStatus"/>
            <NumberInput source="interestRatesPercent" step={1} min={0} />
            <CreatedAtField addLabel/>
            <UpdatedAtField addLabel/>
            <TextField source="lastPhoneUpdateInfo" />
            <TimestampField addLabel source="lastPhoneUpdateDate" />
            <LastLoginField addLabel/>
        </SimpleForm>
    </Edit>
);