import React from "react";
import {
    Create,
    SimpleForm,
    TextInput,
    required
} from 'react-admin';

export const FaqCreate = (props) => (
    <Create {...props}>
        <SimpleForm>
            <TextInput multiline source="question" validate={required()} />
            <TextInput multiline source="answer" validate={required()} />
        </SimpleForm>
    </Create>
);