import React from "react";
import { Chip } from "@material-ui/core";
import { CheckCircle, HighlightOff } from '@material-ui/icons';

type sizeType = 'small' | 'medium';
type colorType = 'default' | 'primary' | 'secondary';

interface Props {
    value: boolean
    labelTrue?: string
    labelFalse?: string
    iconTrue?: React.ReactElement | null
    iconFalse?: React.ReactElement | null
    colorTrue?: colorType,
    colorFalse?: colorType,
    size?: sizeType
}

const BooleanChip = (props: Props) => {
    const {value, size } = props;
    const icon = value ? props.iconTrue : props.iconFalse;
    const color = value ? props.colorTrue : props.colorFalse;
    const label = value ? props.labelTrue : props.labelFalse;

    if (icon) {
        return <Chip size={size} icon={icon} label={label} color={color} />;
    }
    return <Chip size={size} label={label} color={color} />;
}

BooleanChip.defaultProps = {
    labelTrue: 'True',
    labelFalse: 'False',
    iconTrue: <CheckCircle />,
    iconFalse: <HighlightOff />,
    colorTrue: 'primary',
    colorFalse: 'default',
    size: 'small'
}

export default BooleanChip;
