import React from "react";
import {
    Create,
    SimpleForm,
    TextInput,
    PasswordInput,
    CheckboxGroupInput,
    RadioButtonGroupInput,
    required
} from 'react-admin';
import { RoleChoices } from "../types";
import { ROLE_ADMIN, ROLE_SUPER_ADMIN } from "../../../../core/Types/roles";

export const AdminCreate = (props) => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="name" validate={required()} />
            <TextInput source="email" validate={required()} />
            <PasswordInput source="password" validate={required()} />
            <CheckboxGroupInput
                label="Roles"
                source="roles"
                choices={RoleChoices}
                defaultValue={[ROLE_ADMIN]}
            />
        </SimpleForm>
    </Create>
);
