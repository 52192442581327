import React from "react";
import {
    AutocompleteInput,
    Create,
    NullableBooleanInput,
    NumberInput,
    ReferenceInput,
    SelectInput,
    SimpleForm,
    required
} from 'react-admin';
import RichTextInput from "ra-input-rich-text";

export const LessonQuestionAnswerCreate = (props) => (
    <Create {...props}>
        <SimpleForm>
            <ReferenceInput
                source="lessonQuestion"
                reference="lesson-questions"
                perPage={50}
                allowEmpty={true}
                sort={{ field: 'title', order: 'ASC' }}>
                <AutocompleteInput optionText="question" optionValue="id" />
            </ReferenceInput>
            <RichTextInput multiline source="text" validate={required()} />
            <NullableBooleanInput source="correct" validate={required()} />
            <NumberInput source="position" step={1} min={0} validate={required()} />
        </SimpleForm>
    </Create>
);