import * as React from "react";
import {
    Show,
    SimpleShowLayout,
    TextField
} from 'react-admin';
import CreatedAtField from "../../../Field/Common/CreatedAtField";
import UpdatedAtField from "../../../Field/Common/UpdatedAtField";

export const FaqShow = (props) => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField source="question" />
            <TextField source="answer" />
            <CreatedAtField addLabel />
            <UpdatedAtField addLabel />
        </SimpleShowLayout>
    </Show>
);
