import * as React from "react";
import {
    ReferenceField,
    Show,
    SimpleShowLayout,
    TextField
} from 'react-admin';
import CreatedAtField from "../../../Field/Common/CreatedAtField";
import UpdatedAtField from "../../../Field/Common/UpdatedAtField";
import LessonTestAttemptStatusField from "../../../Field/LessonTestAttempt/LessonTestAttemptStatusField";

export const LessonTestAttemptShow = (props) => (
    <Show {...props}>
        <SimpleShowLayout>
            <ReferenceField label="Lesson" source="lesson" reference="lessons">
                <TextField source="title" />
            </ReferenceField>
            <ReferenceField label="User" source="user" reference="users">
                <TextField source="name" />
            </ReferenceField>
            <LessonTestAttemptStatusField component="text" addLabel />
            <CreatedAtField addLabel />
            <UpdatedAtField addLabel />
        </SimpleShowLayout>
    </Show>
);
