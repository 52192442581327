import React from "react";
import {
    Create,
    SimpleForm,
    TextInput,
    SelectInput,
    BooleanInput,
    required
} from 'react-admin';
import { UserGroupChoices } from "../types";
import { USER_GROUP_ALL } from "../../../../core/Types/notification";
import SendingTimeInput from "../../../Input/Notification/SendingTimeInput";
import { FieldProps } from "../../../../core/Types/common";
import { appWindows } from "../../../../config";

const initialValues = {
    message: '',
    userGroup: USER_GROUP_ALL,
    sendingTime: null,
    pushable: false,
}

interface Props extends FieldProps {
    resource: string,
    pagination: {
        current: string
        next: string
        first: string
        last: string
    }
}

export const NotificationCreate = (props: Props) => {
    return (
        <Create {...props}>
            <SimpleForm initialValues={initialValues} redirect='/notifications'>
                <TextInput source="message" validate={required()}/>
                <SelectInput
                    source="userGroup"
                    choices={UserGroupChoices}
                    validate={required()}
                    defaultValue={USER_GROUP_ALL}
                />
                <SendingTimeInput />
                <TextInput source="webUrl" label="Web URL"/>
                <SelectInput source="appUrl" label="App URL" choices={ appWindows } sort={{ field: 'name', order: 'ASC' }} />
                <BooleanInput source="pushable"/>
            </SimpleForm>
        </Create>
    )
};
