import React from "react";
import {
    Show,
    SimpleShowLayout,
    TextField,
    ShowButton,
} from 'react-admin';
import ResourceIdField from "../../../Field/Common/ResourceIdField";
import CreatedAtField from "../../../Field/Common/CreatedAtField";
import MoneyAmountField from "../../../Field/Wallet/MoneyAmountField";
import SenderNameAndPhoneField from "../../../Field/Wallet/SenderNameAndPhoneField";
import BeneficiaryNameAndPhoneField from "../../../Field/Wallet/BeneficiaryNameAndPhoneField";
import WalletTransactionTypeField from "../../../Field/Wallet/WalletTransactionTypeField";
import TransactionStatusField from "../../../Field/Wallet/TransactionStatusField";
import UuidField from "../../../Field/Wallet/UuidField";
import CommentField from "../../../Field/Wallet/CommentField";
import SenderNameField from "../../../Field/Wallet/SenderNameField";
import BeneficiaryNameField from "../../../Field/Wallet/BeneficiaryNameField";
import UpdatedAtField from "../../../Field/Common/UpdatedAtField";
import BankingTransactionTypeField from "../../../Field/Wallet/BankingTransactionTypeField";

export const BankingTransactionShow = (props) => (
    <Show {...props}>
        <SimpleShowLayout>
            <TransactionStatusField addLabel component="text"/>
            <TextField source="accountNumber" addLabel/>
            <MoneyAmountField addLabel/>
            <TextField source="name" addLabel/>
            <TextField source="surname" addLabel/>
            <CommentField addLabel/>
            <BankingTransactionTypeField addLabel component="text"/>
            <UuidField addLabel/>
            <TextField source="externalId" addLabel/>
            <CreatedAtField addLabel/>
            <UpdatedAtField addLabel/>
        </SimpleShowLayout>
    </Show>
)

