import React from "react";
import {
    BANKING_TRANSACTION_TYPE_DEPOSIT,
    BANKING_TRANSACTION_TYPE_WITHDRAWAL
} from "../../../core/Types/wallet";
import EnumTypeField from "../Common/EnumTypeField";

export default class BankingTransactionTypeField extends EnumTypeField
{
    static defaultProps = {
        label: 'Type',
        source: 'type',
        component: 'text'
    }

    getEnumMap(): object {
        return {
            [BANKING_TRANSACTION_TYPE_DEPOSIT]: 'Internal',
            [BANKING_TRANSACTION_TYPE_WITHDRAWAL]: 'Withdrawal',
        };
    }
}
