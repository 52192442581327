import * as React from "react";
import {
    ReferenceField,
    Show,
    SimpleShowLayout,
    TextField
} from 'react-admin';
import CreatedAtField from "../../../Field/Common/CreatedAtField";
import UpdatedAtField from "../../../Field/Common/UpdatedAtField";
import SmsTypeField from "../../../Field/Sms/SmsTypeField";

export default (props) => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField source="receiver" />
            <ReferenceField label="Initiator" source="initiator" reference="users">
                <TextField source="name" />
            </ReferenceField>
            <TextField source="text" />
            <SmsTypeField component="text" addLabel />
            <TextField source="responseCode" />
            <TextField source="responseBody" />
            <CreatedAtField addLabel />
            <UpdatedAtField addLabel />
        </SimpleShowLayout>
    </Show>
);
