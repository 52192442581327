import * as React from "react";
import {
    ReferenceField,
    Show,
    SimpleShowLayout,
    TextField
} from 'react-admin';
import CreatedAtField from "../../../Field/Common/CreatedAtField";
import UpdatedAtField from "../../../Field/Common/UpdatedAtField";

export const TipCompletionShow = (props) => (
    <Show {...props}>
        <SimpleShowLayout>
            <ReferenceField label="Tip" source="tip" reference="tips">
                <TextField source="title" />
            </ReferenceField>
            <ReferenceField label="User" source="user" reference="users">
                <TextField source="name" />
            </ReferenceField>
            <CreatedAtField addLabel />
            <UpdatedAtField addLabel />
        </SimpleShowLayout>
    </Show>
);
