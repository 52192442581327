import * as React from "react";
import {
    Show,
    SimpleShowLayout,
    TextField,
    TopToolbar,
    EditButton
} from 'react-admin';

import CreatedAtField from "../../../Field/Common/CreatedAtField";
import UpdatedAtField from "../../../Field/Common/UpdatedAtField";
import TimestampField from "../../../Field/Common/TimestampField";
import NotificationStatusField from "../../../Field/Notification/NotificationStatusField";
import UserGroupField from "../../../Field/Notification/UserGroupField";
import { STATUS_COMPLETED } from "../../../../core/Types/StatusCodes";

const ActionsToolbar = (props) => {
    const { data } = props

    return (
        <TopToolbar>
            {!!data && data.status === STATUS_COMPLETED
                ? <div />
                : <EditButton {...props} record={data}/>
            }
        </TopToolbar>
    )
}

export const NotificationShow = (props) => (
    <Show actions={<ActionsToolbar />} {...props}>
        <SimpleShowLayout>
            <UserGroupField addLabel />
            <TextField source="message" />
            <TimestampField source="sendingTime" addLabel />
            <NotificationStatusField addLabel/>
            <TextField source="webUrl" label="Web URL" />
            <TextField source="appUrl" label="App URL" />
            <CreatedAtField addLabel />
            <UpdatedAtField addLabel />
        </SimpleShowLayout>
    </Show>
);
