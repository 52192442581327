import React from "react";
import {NullableBooleanInput, NumberInput, required, SelectArrayInput} from 'react-admin';
import RichTextInput from "ra-input-rich-text";

import DialogContent from "@material-ui/core/DialogContent";

export const selectArrayInput = (
    <SelectArrayInput optionText="text"/>
);

export const dialogContent = (
    <DialogContent>
        <RichTextInput multiline source="text" validate={required()} fullWidth/>
        <NullableBooleanInput source="correct" validate={required()} fullWidth/>
        <NumberInput source="position" step={1} min={0} validate={required()} fullWidth/>
    </DialogContent>
);