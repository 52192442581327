import React from "react";
import {
    Edit,
    SimpleForm,
    TextInput,
    SelectInput,
    BooleanInput,
    required,
} from "react-admin";
import { UserGroupChoices } from "../types";
import SendingTimeInput from "../../../Input/Notification/SendingTimeInput";

export const NotificationEdit = (props) => {
    return (
        <Edit {...props}>
            <SimpleForm>
                <TextInput source="message" validate={required()} />
                <SelectInput source="userGroup" choices={UserGroupChoices} validate={required()} />
                <SendingTimeInput />
                <BooleanInput source="pushable" />
            </SimpleForm>
        </Edit>
    );
}
