import React from "react";
import config from "../../../config";
import { DateField } from 'react-admin';

class TimestampField extends React.Component<any>
{
    render() {
        return <DateField locales={config.dateTimeLocale} showTime {...this.props} />
    }
}

export default TimestampField;
