import * as React from "react";
import {
    BooleanField,
    NumberField,
    ReferenceField,
    Show,
    SimpleShowLayout,
    TextField,
    TopToolbar,
} from 'react-admin';
import CreatedAtField from "../../../Field/Common/CreatedAtField";
import UpdatedAtField from "../../../Field/Common/UpdatedAtField";
import TimestampField from "../../../Field/Common/TimestampField";

export default (props) => (
    <Show {...props} actions={<TopToolbar />} >
        <SimpleShowLayout>
            <ReferenceField label="User" source="user" reference="users">
                <TextField source="name" />
            </ReferenceField>
            <ReferenceField label="Subscription" source="subscription" reference="subscriptions">
                <TextField source="title" />
            </ReferenceField>
            <ReferenceField label="Discount code" source="discountCode" reference="discount-codes">
                <TextField source="title" />
            </ReferenceField>
            <NumberField source="finalPrice"/>
            <BooleanField source="paid" />
            <TimestampField source="activeSince" />
            <BooleanField source="expired" />
            <TimestampField source="endDate"/>
            <CreatedAtField addLabel />
            <UpdatedAtField addLabel />
        </SimpleShowLayout>
    </Show>
);
