import React from "react";
import { TextField } from 'react-admin';

const CommentField= (props) => (
    <TextField {...props}/>
)

CommentField.defaultProps = { label: "Comment", source: "comment" }

export default CommentField;
