import React, {useCallback, useState} from 'react';
import {ReferenceArrayInput} from 'react-admin';
import {makeStyles} from '@material-ui/core/styles';

import QuickCreateButton from './QuickCreateButton';

const useStyles = makeStyles({
    root: {
        display: 'flex',
        alignItems: 'center'
    }
});

const QuickCreateReferenceInput = props => {
    const classes = useStyles();
    const [version, setVersion] = useState(0);
    const handleChange = useCallback(() => setVersion(version + 1), [version]);
    const selectArrayInput = props.selectArrayInput;

    return (
        <div className={classes.root}>
            <ReferenceArrayInput key={version} {...props}>
                {selectArrayInput}
            </ReferenceArrayInput>

            <QuickCreateButton
                onChange={handleChange}
                resource={props.reference}
                label={props.title}
                dialogContent={props.dialogContent}
            />
        </div>
    );
};

export default QuickCreateReferenceInput;
