import React from "react";
import TimestampField from "./TimestampField";

class CreatedAtField extends TimestampField
{
    static defaultProps = {
        label: 'Created at',
        source: 'createdAt',
    }
}

export default CreatedAtField;