import React from "react";
import {Create, SimpleForm} from 'react-admin';

export default (props) => {
    return (
        <Create {...props}>
            <SimpleForm>
            </SimpleForm>
        </Create>
    );
}
