import * as React from "react";
import { Show, SimpleShowLayout, TextField } from 'react-admin';
import CreatedAtField from "../../../Field/Common/CreatedAtField";
import UpdatedAtField from "../../../Field/Common/UpdatedAtField";
import LastLoginField from "../../../Field/Common/LastLoginField";

export const AdminShow = (props) => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField source="name"/>
            <TextField source="email"/>
            <CreatedAtField addLabel/>
            <UpdatedAtField addLabel/>
            <LastLoginField addLabel/>
        </SimpleShowLayout>
    </Show>
);
