import React from "react";
import {
    AutocompleteInput,
    Create,
    NullableBooleanInput,
    NumberInput,
    ReferenceArrayInput,
    ReferenceInput,
    required,
    SelectArrayInput,
    SelectInput,
    SimpleForm,
    TextInput
} from "react-admin";
import RichTextInput from "ra-input-rich-text";
import {Typography} from '@material-ui/core';
import QuickCreateReferenceInput from '../../../Input/QuickCreate/QuickCreateReferenceInput'
import {dialogContent, selectArrayInput} from "../QuickCreate/Components";

const Aside = () => (
    <div style={{ width: 200, margin: '1em' }}>
        <Typography variant="h6">Order no</Typography>
        <Typography variant="body2">
            Providing no order value will make it to be the largest one within lesson topic.
        </Typography>
    </div>
);

export const LessonCreate = (props) => (
    <Create aside={<Aside />} {...props}>
        <SimpleForm>
            <TextInput source="title" validate={required()} />
            <ReferenceInput
                source="topic"
                reference="lesson-topics"
                perPage={50}
                sort={{ field: 'title', order: 'ASC' }}
                validate={required()}>
                <AutocompleteInput optionText="title" optionValue="id" />
            </ReferenceInput>
            <RichTextInput source="description" label="Additional description" />
            <RichTextInput source="greeting" />
            <RichTextInput source="introduction" />
            <NumberInput source="position" step={1} min={0} />
            <QuickCreateReferenceInput
                source="materials"
                reference="lesson-materials"
                label="Materials"
                title="Create lesson material"
                selectArrayInput={selectArrayInput}
                dialogContent={dialogContent}
                filter={{ "exists[lesson]": false }}
            />
            <ReferenceArrayInput
                source="questions"
                reference="lesson-questions"
                label="Questions"
                filter={{ "exists[lesson]": false }}>
                <SelectArrayInput optionText="question" />
            </ReferenceArrayInput>
            <NullableBooleanInput label="For subscribers only" source="forSubscribersOnly" validate={required()} />
            <NullableBooleanInput source="published" validate={required()} />
        </SimpleForm>
    </Create>
);