import React from "react";
import {Create, required, SimpleForm, TextInput} from 'react-admin';

export default (props) => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="phone" validate={required()} />
            <TextInput source="comment" />
        </SimpleForm>
    </Create>
);