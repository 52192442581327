import React from "react";
import {
    Datagrid,
    TextField,
    EditButton,
    ShowButton,
    FunctionField
} from 'react-admin';
import useStyles from "../../../../styles";
import ResourceIdField from "../../../Field/Common/ResourceIdField";
import CreatedAtField from "../../../Field/Common/CreatedAtField";
import MoneyAmountField from "../../../Field/Wallet/MoneyAmountField";
import TransactionStatusField from "../../../Field/Wallet/TransactionStatusField";
import UuidField from "../../../Field/Wallet/UuidField";
import CommentField from "../../../Field/Wallet/CommentField";
import BankingTransactionTypeField from "../../../Field/Wallet/BankingTransactionTypeField";
import ListConfirmDelete from "../../../Element/List/ListConfirmDelete";

export const BankingTransactionsList = (props) => {
    const classes = useStyles();
    return (
        <ListConfirmDelete {...props}>
            <Datagrid>
                <ResourceIdField cellclassname={classes.narrow}/>
                <CreatedAtField cellclassname={classes.narrow}/>
                <UuidField cellclassname={classes.narrow}/>
                <TextField source="accountNumber" cellclassname={classes.narrow}/>
                <MoneyAmountField cellclassname={classes.narrow}/>
                <FunctionField label="Name" render={record => `${record.name} ${record.surname}`} />
                <CommentField />
                <BankingTransactionTypeField />
                <TransactionStatusField cellclassname={classes.narrow}/>
                <ShowButton cellclassname={classes.narrow} />
                <EditButton cellclassname={classes.narrow} />
            </Datagrid>
        </ListConfirmDelete>
    );
};
