import React from "react";
import {
    Create,
    ImageInput,
    ImageField,
    SimpleForm,
    TextInput,
    required
} from "react-admin";
import RichTextInput from "ra-input-rich-text";

export const LessonTopicCreate = (props) => {
    return (
        <Create {...props}>
            <SimpleForm>
                <TextInput source="title" validate={required()} />
                <RichTextInput source="description" validate={required()} />
                <ImageInput source="encodedImage" label="Image" accept="image/*">
                    <ImageField source="image" title="Image" />
                </ImageInput>
            </SimpleForm>
        </Create>
    );
}
