import React from "react";
import { Chip, Typography } from "@material-ui/core";
import { FieldProps } from "../../../core/Types/common";

interface Props extends FieldProps{
    component?: 'text' | 'chip'
}

const getTypeLabel = (status: number): string => {
    return status === 1 ? 'Passed' : 'Failed'
}

const LessonTestAttemptStatusField = (props: Props) => {
    const {
        record: { status },
        component
    } = props;

    if (component && component === 'text') {
        return <Typography>{ status === 1 ? 'Passed' : 'Failed' }</Typography>;
    }

    return <Chip size="small" label={getTypeLabel(status)} />;
}

LessonTestAttemptStatusField.defaultProps = { label: "Status" }

export default LessonTestAttemptStatusField;