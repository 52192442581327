import React from "react";
import EnumTypeField from "../Common/EnumTypeField";
import {
    USER_GROUP_PARENT,
    USER_GROUP_CHILD,
    USER_GROUP_ALL,
} from "../../../core/Types/notification";

class UserGroupField extends EnumTypeField
{
    static defaultProps = {
        source: 'userGroup',
        label: 'User group'
    };

    getEnumMap(): object {
        return {
            [USER_GROUP_PARENT]: 'Parents',
            [USER_GROUP_CHILD]: 'Children',
            [USER_GROUP_ALL]: 'All',
        }
    }
}

export default UserGroupField;
