import React from "react";
import {
    Datagrid,
    NumberField,
    ReferenceField,
    ShowButton,
    TextField,
} from 'react-admin';
import ResourceIdField from "../../../Field/Common/ResourceIdField";
import CreatedAtField from "../../../Field/Common/CreatedAtField";
import UpdatedAtField from "../../../Field/Common/UpdatedAtField";
import TaskStatusField from "../../../Field/Task/TaskStatusField";
import ListConfirmDelete from "../../../Element/List/ListConfirmDelete";

export const TasksList = (props) => (
    <ListConfirmDelete {...props}>
        <Datagrid>
            <ResourceIdField record />
            <CreatedAtField record />
            <UpdatedAtField record />
            <ReferenceField label="Owner" source="owner" reference="users">
                <TextField source="name" />
            </ReferenceField>
            <ReferenceField label="Assignee" source="assignee" reference="users">
                <TextField source="name" />
            </ReferenceField>
            <ReferenceField label="Recommended task" source="recommendedTask" reference="recommended-tasks">
                <TextField source="title" />
            </ReferenceField>
            <TextField source="title" />
            <NumberField source="estimatedPay" />
            <TaskStatusField component="text" addLabel />
            <ShowButton />
        </Datagrid>
    </ListConfirmDelete>
);