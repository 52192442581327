import React from "react";
import { Chip, Typography } from "@material-ui/core";
import { FieldProps } from "../../../core/Types/common";

interface Props extends FieldProps{
    component?: 'text' | 'chip'
}

const getTypeLabel = (status: string): string => {
    switch (status) {
        case 'rejected': return 'Rejected'
        case 'approving': return 'Approving'
        case 'in_progress': return 'In progress'
        case 'evaluating': return 'Evaluating'
        case 'completed': return 'Completed'
        default: return ''
    }
}

const TaskStatusField = (props: Props) => {
    const {
        record: { status },
        component
    } = props;

    const statusTranslation = getTypeLabel(status);

    if (component && component === 'text') {
        return <Typography>{ statusTranslation }</Typography>;
    }

    return <Chip size="small" label={statusTranslation} />;
}

TaskStatusField.defaultProps = { label: "Status" }

export default TaskStatusField;