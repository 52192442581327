import * as React from "react";
import {Show, SimpleShowLayout, TextField} from 'react-admin';
import CreatedAtField from "../../../Field/Common/CreatedAtField";
import UpdatedAtField from "../../../Field/Common/UpdatedAtField";

export default (props) => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField source="phone" />
            <TextField source="comment" />
            <CreatedAtField addLabel />
            <UpdatedAtField addLabel />
        </SimpleShowLayout>
    </Show>
);
