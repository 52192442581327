import React from "react";
import {
    AutocompleteInput,
    Create,
    NumberInput,
    ReferenceInput,
    SelectInput,
    SimpleForm,
    required
} from "react-admin";
import RichTextInput from "ra-input-rich-text";

export const LessonMaterialCreate = (props) => (
    <Create {...props}>
        <SimpleForm>
            <ReferenceInput
                source="lesson"
                reference="lessons"
                perPage={50}
                allowEmpty={true}
                sort={{ field: 'title', order: 'ASC' }}>
                <AutocompleteInput optionText="title" optionValue="id" />
            </ReferenceInput>
            <RichTextInput source="title" validate={required()} />
            <RichTextInput source="description" validate={required()} />
            <RichTextInput source="submitButton" validate={required()} />
            <RichTextInput source="finishText" label="Additional description" />
            <NumberInput source="position" step={1} min={0} validate={required()} />
        </SimpleForm>
    </Create>
);