import * as React from "react";
import {
    BooleanField,
    Datagrid,
    EditButton,
    NumberField,
    ReferenceField,
    ReferenceArrayField,
    RichTextField,
    Show,
    SimpleShowLayout,
    TextField
} from 'react-admin';
import CreatedAtField from "../../../Field/Common/CreatedAtField";
import UpdatedAtField from "../../../Field/Common/UpdatedAtField";

export const LessonShow = (props) => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField source="title" />
            <ReferenceField label="Lesson topic" source="topic" reference="lesson-topics">
                <TextField source="title" />
            </ReferenceField>
            <RichTextField source="description" label="Additional description" />
            <RichTextField source="greeting" />
            <RichTextField source="introduction" />
            <NumberField source="position" />
            <ReferenceArrayField
                reference="lesson-questions"
                source="questions"
                label="Questions">
                <Datagrid>
                    <TextField source="question" />
                    <TextField source="position" />
                    <EditButton />
                </Datagrid>
            </ReferenceArrayField >
            <ReferenceArrayField
                reference="lesson-materials"
                source="materials"
                label="Materials">
                <Datagrid>
                    <RichTextField source="title" />
                    <RichTextField source="description" />
                    <RichTextField source="submitButton" />
                    <RichTextField source="finishText" label="Additional description" />
                    <TextField source="position" />
                    <EditButton />
                </Datagrid>
            </ReferenceArrayField >
            <BooleanField source="forSubscribersOnly" />
            <BooleanField source="published" />
            <CreatedAtField addLabel />
            <UpdatedAtField addLabel />
        </SimpleShowLayout>
    </Show>
);
