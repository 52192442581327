import * as React from "react";
import { FieldProps } from "../../../core/Types/common";
import { EditButton } from 'react-admin';

interface Props extends FieldProps
{
    disabledStatus: number|number[]
}

const StatusAwareEditButton = (props: Props) => {
    const { record, disabledStatus } = props;
    const disabled = record.status === disabledStatus ||
        (Array.isArray(disabledStatus) && disabledStatus.indexOf(record.status) >= 0)

    return (
        <EditButton
            {...props}
            disabled={disabled}
            color={disabled ? 'grey' : 'primary'}
        />
    );
};

export default StatusAwareEditButton;
