import React from "react";
import {
    Datagrid,
    EditButton,
    NumberField,
    ShowButton,
} from 'react-admin';
import ResourceIdField from "../../../Field/Common/ResourceIdField";
import CreatedAtField from "../../../Field/Common/CreatedAtField";
import UpdatedAtField from "../../../Field/Common/UpdatedAtField";
import useStyles from "../../../../styles";
import ListConfirmDelete from "../../../Element/List/ListConfirmDelete";

export default (props) => {
    const classes = useStyles();
    return (
        <ListConfirmDelete {...props}>
            <Datagrid>
                <ResourceIdField cellclassname={classes.narrow} />
                <CreatedAtField />
                <UpdatedAtField />
                <NumberField source="months"/>
                <ShowButton />
                <EditButton />
            </Datagrid>
        </ListConfirmDelete>
    );
};