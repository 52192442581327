import React from "react";
import { FieldProps } from "../../../core/Types/common";
import BooleanChip from "../../Element/Chip/BooleanChip";

const UserActiveStatusField = (props: FieldProps ) => {
    const { record, source } = props
    return <BooleanChip value={!!record[source]} labelTrue="Active" labelFalse="Banned"/>
}

UserActiveStatusField.defaultProps = { label: 'Status', source: 'isActive', sortBy: 'isActive' }

export default UserActiveStatusField;
