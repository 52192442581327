import React from "react";
import {
    Edit,
    SimpleForm,
    TextInput,
    required
} from 'react-admin';

export const RecommendedTaskEdit = (props) => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput source="title" validate={required()} />
        </SimpleForm>
    </Edit>
);