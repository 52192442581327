import React from "react";
import {
    AutocompleteInput,
    Edit,
    ReferenceInput,
    SelectInput,
    SimpleForm,
    required
} from 'react-admin';
import { StatusChoices } from "../statusTypes";

export const LessonTestAttemptEdit = (props) => {
    return (
        <Edit {...props}>
            <SimpleForm>
                <ReferenceInput
                    source="lesson"
                    reference="lessons"
                    perPage={50}
                    sort={{ field: 'title', order: 'ASC' }}
                    validate={required()}>
                    <AutocompleteInput optionText="title" optionValue="id" />
                </ReferenceInput>
                <ReferenceInput
                    source="user"
                    reference="users"
                    perPage={50}
                    sort={{ field: 'name', order: 'ASC' }}
                    validate={required()}>
                    <AutocompleteInput optionText="name" optionValue="id" />
                </ReferenceInput>
                <SelectInput source="status" validate={required()} choices={StatusChoices} />
            </SimpleForm>
        </Edit>
    );
}
