import React from "react";
import { NumberField }  from 'react-admin';
import { FieldProps } from "../../../core/Types/common";
import config from "../../../config";

interface Props extends FieldProps {
    options?: object;
}

const MoneyAmountField = (props: Props) => {
    const { record, source } = props;
    record[source] *= config.money.factor;

    return <NumberField {...props} record={record}/>
}

//TODO: use currency from record
MoneyAmountField.defaultProps = {
    source: 'amount',
    options: {
        style: 'currency',
        locales: config.money.locale,
        currency: config.money.currency,
        maximumFractionDigits: config.money.precision
    }
}

export default MoneyAmountField;
