import ConfigInterface from "./core/Types/ConfigType";

const config: ConfigInterface = {
    locale: 'en',
    dateTimeLocale: 'lt-LT',
    translationLanguages: ['lt', 'en'],
    env: process.env.NODE_ENV,
    money: {
        currency: 'EUR',
        locale: 'lt-LT',
        precision: 2,
        factor: 0.01
    },
    api: {
        entrypoint: {
            root: `${process.env.REACT_APP_API_ENTRYPOINT}`,
            adminLogin: `${process.env.REACT_APP_ADMIN_ENTRYPOINT}/login`,
            adminLogout: `${process.env.REACT_APP_ADMIN_ENTRYPOINT}/logout`
        },
        token: {
            headerName: 'Authorization',
            valuePrefix: 'Bearer ',
            serverTokenKey: 'token',
            localTokenKey: 'token',
        },
        pagination: {
            itemsPerPage: 25
        }
    },
    tokenCookie: {
        name: `${process.env.REACT_APP_TOKEN_COOKIE_NAME}`,
        lifetime: parseInt(`${process.env.REACT_APP_TOKEN_COOKIE_LIFETIME}`),
        domain: `${process.env.REACT_APP_TOKEN_COOKIE_DOMAIN}`,
        path: `${process.env.REACT_APP_TOKEN_COOKIE_PATH}`,
    },
};

export const appWindows = [
    { id: 'WalletTab',                  name: 'WalletTab'               },
    { id: 'TasksTab',                   name: 'TasksTab'                },
    { id: 'LessonsTab',                 name: 'LessonsTab'              },
    { id: 'SettingsTab',                name: 'SettingsTab'             },
    { id: 'CardTab',                    name: 'CardTab'                 },
    { id: 'TopicView_parent',           name: 'TopicView_parent'        },
    { id: 'lessonTipsListContainer',    name: 'lessonTipsListContainer' }
];

export default config;
