import * as React from "react";
import {NumberField, ReferenceField, Show, SimpleShowLayout, TextField} from 'react-admin';
import CreatedAtField from "../../../Field/Common/CreatedAtField";
import UpdatedAtField from "../../../Field/Common/UpdatedAtField";

export default (props) => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField source="title" />
            <ReferenceField label="Subscription duration" source="subscriptionDuration" reference="subscription-durations">
                <NumberField source="months" />
            </ReferenceField>
            <NumberField source="price" />
            <CreatedAtField addLabel />
            <UpdatedAtField addLabel />
        </SimpleShowLayout>
    </Show>
);
