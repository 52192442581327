import React from "react";
import {
    AutocompleteInput,
    Create,
    DateTimeInput,
    NullableBooleanInput,
    ReferenceInput,
    SelectInput,
    SimpleForm,
    required
} from 'react-admin';

export default (props) => {
    return (
        <Create {...props}>
            <SimpleForm redirect='/user-subscriptions'>
                <ReferenceInput
                    source="user"
                    reference="users"
                    perPage={50}
                    sort={{ field: 'name', order: 'ASC' }}
                    validate={required()}>
                    <AutocompleteInput optionText="name" optionValue="id" />
                </ReferenceInput>
                <ReferenceInput
                    source="subscription"
                    reference="subscriptions"
                    perPage={50}
                    sort={{ field: 'title', order: 'ASC' }}
                    validate={required()}>
                    <AutocompleteInput optionText="title" optionValue="id" />
                </ReferenceInput>
                <ReferenceInput
                    source="discountCode"
                    reference="discount-codes"
                    perPage={50}
                    sort={{ field: 'title', order: 'ASC' }}>
                    <AutocompleteInput optionText="title" optionValue="id" />
                </ReferenceInput>
                <NullableBooleanInput source="paid" validate={required()} />
                <DateTimeInput source="activeSince" />
            </SimpleForm>
        </Create>
    );
}
