import { tokenStorage } from './tokenStorage';
import config from "../config";
import { userStorage } from "../core/Model/SessionUser/userStorage";
import { extractErrorMessage, validateToken } from "./utils";
import { SessionUser } from "../core/Model/SessionUser/SessionUser";
const axios = require('axios');

const rejectError = (error) => {
    if (config.env === 'development') {
        console.log(error);
    }
    return Promise.reject(extractErrorMessage(error));
}

export const authProvider = {
    login: (data) =>  {
        const { username: email, password } = data;
        return axios.post(
            config.api.entrypoint.adminLogin, {
                email,
                password
            }, {
                headers: {
                    'Content-Type': 'application/json'
                }
            }
        ).then(({ data }) => {
            const token = data[config.api.token.serverTokenKey];
            tokenStorage.setToken(token);

            const sessionUser = SessionUser.fromToken(token);
            userStorage.setUser(sessionUser);

            //force reload
            window.location.reload();
        }).catch((error) => {
            return rejectError(error);
        });
    },
    logout: () => {
        return axios.post(config.api.entrypoint.adminLogout)
            .then((response) => {
                tokenStorage.removeToken();
                userStorage.removeUser();
                window.location.reload();
            }).catch((error) => {
                tokenStorage.removeToken();
                userStorage.removeUser();
                window.location.reload();
            });
    },
    checkAuth: params => {
        const token = tokenStorage.getToken();
        if (token && validateToken(token)) {
            return Promise.resolve();
        }
        return Promise.reject();
    },
    checkError: ({ response, error }) => {
        let errorStatus;

        if (error && !!error.status) {
            errorStatus = error.status;
        }

        if (response && !!response.status) {
            errorStatus = response.status;
        }

        switch (errorStatus) {
            case 401:
                tokenStorage.removeToken();
                userStorage.removeUser();
                return Promise.reject();
            default:
                return Promise.resolve();
        }
    },
    getPermissions: params => {
        return Promise.resolve();
    },
};
