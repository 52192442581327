import React from "react";
import {
    AutocompleteInput,
    Edit,
    NullableBooleanInput,
    NumberInput,
    ReferenceArrayInput,
    ReferenceInput,
    required,
    SelectArrayInput,
    SelectInput,
    SimpleForm,
    TextInput
} from "react-admin";
import RichTextInput from "ra-input-rich-text";
import QuickCreateReferenceInput from '../../../Input/QuickCreate/QuickCreateReferenceInput'
import {dialogContent, selectArrayInput} from "../QuickCreate/Components";


export const LessonEdit = (props) => {
    return (
        <Edit {...props}>
            <SimpleForm>
                <TextInput source="title" validate={required()} />
                <ReferenceInput
                    source="topic"
                    reference="lesson-topics"
                    perPage={50}
                    sort={{ field: 'title', order: 'ASC' }}
                    validate={required()}>
                    <AutocompleteInput optionText="title" optionValue="id" />
                </ReferenceInput>
                <RichTextInput source="description" label="Additional description" />
                <RichTextInput source="greeting" />
                <RichTextInput source="introduction" />
                <NumberInput source="position" step={1} min={0} validate={required()} />
                <QuickCreateReferenceInput
                    source="materials"
                    reference="lesson-materials"
                    label="Materials"
                    title="Create lesson material"
                    selectArrayInput={selectArrayInput}
                    dialogContent={dialogContent}
                    filter={{ "exists[lesson]": false }}
                />
                <ReferenceArrayInput
                    source="questions"
                    reference="lesson-questions"
                    label="Questions"
                    filter={{ "exists[lesson]": false }}>
                    <SelectArrayInput optionText="question" />
                </ReferenceArrayInput>
                <NullableBooleanInput label="For subscribers only" source="forSubscribersOnly" validate={required()} />
                <NullableBooleanInput source="published" validate={required()} />
            </SimpleForm>
        </Edit>
    );
}
