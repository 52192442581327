import React from "react";
import { Chip } from "@material-ui/core";
import { FieldProps } from "../../../core/Types/common";
import {
    FIXED, PERCENT
} from "../../../core/Types/DiscountCodeTypes";

interface Props extends FieldProps{
    component?: 'text' | 'chip'
}

const getTypeLabel = (type: number): string => {
    switch (type) {
        case FIXED: return 'Fixed'
        case PERCENT: return 'Percent'
        default: return ''
    }
}

const DiscountCodeTypeField = (props: Props) => {
    const {
        record: { type },
        component
    } = props;

    if (component && component === 'text') {
        return <div>{ getTypeLabel(type) }</div>;
    }

    return <Chip size="small" label={getTypeLabel(type)} />;
}

DiscountCodeTypeField.defaultProps = { label: "Type" }

export default DiscountCodeTypeField;