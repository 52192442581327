import React from "react";
import {
    AutocompleteInput,
    Create,
    ImageInput,
    ImageField,
    NumberInput,
    ReferenceInput,
    SelectInput,
    SimpleForm,
    TextInput,
    required
} from 'react-admin';

export const LessonProgressLevelCreate = (props) => {
    return (
        <Create {...props}>
            <SimpleForm>
                <TextInput source="title" validate={required()} />
                <NumberInput source="testsToPassAmount" step={1} min={0} validate={required()} />
                <ReferenceInput
                    source="predecessor"
                    reference="lesson-progress-levels"
                    perPage={50}
                    sort={{ field: 'title', order: 'ASC' }}>
                    <AutocompleteInput optionText="title" optionValue="id" />
                </ReferenceInput>
                <ImageInput source="encodedImage" label="Image" accept="image/*">
                    <ImageField source="image" title="Image" />
                </ImageInput>
            </SimpleForm>
        </Create>
    );
}
