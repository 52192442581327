import React from "react";
import {
    AutocompleteInput,
    Edit,
    NumberInput,
    ReferenceInput,
    required,
    SelectInput,
    SimpleForm,
    TextInput
} from 'react-admin';

export default (props) => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput source="title" validate={required()}/>
            <ReferenceInput
                source="subscriptionDuration"
                reference="subscription-durations"
                perPage={50}
                sort={{field: 'months', order: 'ASC'}}
                validate={required()}>
                <SelectInput optionText="months" />
            </ReferenceInput>
            <NumberInput source="price" step={0.01} min={0} validate={required()}/>
        </SimpleForm>
    </Edit>
);
