import { makeStyles } from '@material-ui/core';

//TODO: delete link
//https://marmelab.com/react-admin/List.html#row-style-function

const useStyles = makeStyles(theme => ({
    tableHeading: {
        color: 'rgba(0, 0, 0, 0.54)',
        marginTop: '10px',
        fontSize: '0.875rem',
    },
    narrow: {
        width: '1%',
        whiteSpace: 'nowrap',
    },
    embedTable: {
        maxWidth: '920px',
        marginBottom: '2em'
    },
    fw100: { minWidth: '100px' },
    fw125: { minWidth: '125px' },
    fw150: { minWidth: '150px' },
    fw175: { minWidth: '175px' },
    fw200: { minWidth: '200px' },
}));

export default useStyles;
