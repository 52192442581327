import React from "react";
import {
    Create,
    NumberInput,
    SelectInput,
    SimpleForm,
    required
} from 'react-admin';
import {EuropeCountryChoices} from "../../../../core/Types/country";
import TaxCategories from "../categories";

export default (props) => (
    <Create {...props}>
        <SimpleForm>
            <SelectInput source="category" validate={required()} choices={TaxCategories} />
            <SelectInput source="country" validate={required()} choices={EuropeCountryChoices} />
            <NumberInput label="Price" source="price" step={0.01} min={0} validate={required()}/>
        </SimpleForm>
    </Create>
);