import React from "react";
import {
    BooleanField,
    Datagrid,
    EditButton,
    NumberField,
    ReferenceArrayField,
    ReferenceField,
    RichTextField,
    Show,
    Edit,
    SimpleShowLayout,
    SimpleForm,
    TextField,
    TextInput,
    ShowController
} from 'react-admin';
import ResourceIdField from "../../../Field/Common/ResourceIdField";
import CreatedAtField from "../../../Field/Common/CreatedAtField";

export const FeedbackEdit = (props) => (
    <ShowController {...props}>
        {controllerProps => <Edit {...props} {...controllerProps}>
            <SimpleForm>
                <ResourceIdField addLabel />
                <CreatedAtField addLabel />
                <ReferenceField source="user" reference="users">
                    <TextField source="name" />
                </ReferenceField>
                <TextField source="subject" />
                <TextField source="message" />
                {controllerProps.record && controllerProps.record.feedback === null ?
                    <TextInput source="feedback" /> :
                    <TextField source="feedback" />
                }
            </SimpleForm>
        </Edit>
        }
    </ShowController>
);
