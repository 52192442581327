import React from "react";
import EnumTypeField from "../Common/EnumTypeField";
import { CheckCircle, Timelapse } from '@material-ui/icons';
import {
    STATUS_COMPLETED,
    STATUS_PENDING,
} from "../../../core/Types/StatusCodes";

class NotificationStatusField extends EnumTypeField
{
    static defaultProps = {
        source: 'status',
        label: 'Status'
    };

    getEnumMap(): object {
        return {
            [STATUS_COMPLETED]: 'Completed',
            [STATUS_PENDING]: 'Pending',
        }
    }

    getColorMap(): object {
        return {
            [STATUS_COMPLETED]: 'primary'
        }
    }

    getIconMap(): object {
        return {
            [STATUS_COMPLETED]: <CheckCircle />,
            [STATUS_PENDING]: <Timelapse />,
        }
    }
}

export default NotificationStatusField;
