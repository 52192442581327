import * as React from "react";
import {NumberField, Show, SimpleShowLayout, TextField} from 'react-admin';
import CreatedAtField from "../../../Field/Common/CreatedAtField";
import UpdatedAtField from "../../../Field/Common/UpdatedAtField";
import DiscountCodeTypeField from "../../../Field/DiscountCode/DiscountCodeTypeField";
import TimestampField from "../../../Field/Common/TimestampField";

export const DiscountCodeShow = (props) => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField source="title" />
            <TextField source="code" />
            <DiscountCodeTypeField component="text" addLabel />
            <NumberField source="discountSize" />
            <NumberField source="duration" />
            <TimestampField addLabel source="validityBegin" />
            <TimestampField addLabel source="validityEnd" />
            <NumberField label="Usages" source="userSubscriptionsCount" />
            <CreatedAtField addLabel />
            <UpdatedAtField addLabel />
        </SimpleShowLayout>
    </Show>
);
