import React from "react";
import {
    AutocompleteInput,
    Create,
    NumberInput,
    ReferenceInput,
    required,
    SelectInput,
    SimpleForm
} from 'react-admin';
import QuickCreateReferenceInput from '../../../Input/QuickCreate/QuickCreateReferenceInput'
import RichTextInput from "ra-input-rich-text";
import {dialogContent, selectArrayInput} from '../QuickCreate/Components'

export const LessonQuestionCreate = (props) => (
    <Create {...props}>
        <SimpleForm>
            <ReferenceInput
                source="lesson"
                reference="lessons"
                perPage={50}
                allowEmpty={true}
                sort={{ field: 'title', order: 'ASC' }}>
                <AutocompleteInput optionText="title" optionValue="id" />
            </ReferenceInput>

            <RichTextInput multiline source="question" validate={required()} />
            <RichTextInput source="wrongAnswerText" validate={required()} />
            <RichTextInput source="correctAnswerText" validate={required()} />
            <NumberInput source="position" step={1} min={0} validate={required()} />
            <QuickCreateReferenceInput
                source="answers"
                reference="lesson-question-answers"
                label="Answers"
                title="Create lesson question answer"
                selectArrayInput={selectArrayInput} //@TODO: this is broken
                dialogContent={dialogContent}
                filter={{ "exists[lessonQuestion]": false }}
            />
        </SimpleForm>
    </Create>
);