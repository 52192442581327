import React from "react";
import {
    Datagrid,
    TextField,
    ShowButton,
} from 'react-admin';
import ResourceIdField from "../../../Field/Common/ResourceIdField";
import CreatedAtField from "../../../Field/Common/CreatedAtField";
import MoneyAmountField from "../../../Field/Wallet/MoneyAmountField";
import TransactionStatusField from "../../../Field/Wallet/TransactionStatusField";
import WalletTransactionTypeField from "../../../Field/Wallet/WalletTransactionTypeField";
import useStyles from "../../../../styles";
import SenderNameAndPhoneField from "../../../Field/Wallet/SenderNameAndPhoneField";
import BeneficiaryNameAndPhoneField from "../../../Field/Wallet/BeneficiaryNameAndPhoneField";
import CommentField from "../../../Field/Wallet/CommentField";
import ListConfirmDelete from "../../../Element/List/ListConfirmDelete";

export const WalletTransactionsList = (props) => {
    const classes = useStyles();
    return (
        <ListConfirmDelete {...props}>
            <Datagrid>
                <ResourceIdField cellclassname={classes.narrow}/>
                <CreatedAtField cellclassname={classes.narrow}/>
                <TextField source="uuid" cellclassname={classes.narrow}/>
                <MoneyAmountField cellclassname={classes.narrow}/>
                <SenderNameAndPhoneField cellclassname={classes.narrow}/>
                <BeneficiaryNameAndPhoneField cellclassname={classes.narrow}/>
                <CommentField />
                <WalletTransactionTypeField cellclassname={classes.narrow}/>
                <TransactionStatusField cellclassname={classes.narrow}/>
                <ShowButton cellclassname={classes.narrow} />
            </Datagrid>
        </ListConfirmDelete>
    );
};
