import React from "react";
import {
    AutocompleteInput,
    BooleanInput,
    Edit,
    ImageInput,
    ImageField,
    NumberInput,
    ReferenceInput,
    SelectInput,
    SimpleForm,
    TextInput,
    required
} from 'react-admin';

export const LessonProgressLevelEdit = (props) => {
    return (
        <Edit {...props}>
            <SimpleForm>
                <TextInput source="title" validate={required()} />
                <NumberInput source="testsToPassAmount" step={1} min={0} validate={required()} />
                <ReferenceInput
                    source="predecessor"
                    reference="lesson-progress-levels"
                    perPage={50}
                    sort={{ field: 'title', order: 'ASC' }}>
                    <AutocompleteInput optionText="title" optionValue="id" />
                </ReferenceInput>
                <ImageField source="image" title="Image" />
                <ImageInput source="encodedImage" label="Change image" accept="image/*">
                    <ImageField source="image" title="Image" />
                </ImageInput>
                <BooleanInput source="removeImage" />
            </SimpleForm>
        </Edit>
    );
}
