import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
    overrides: {
        MuiFormControl: {
            root: {
                width: '700px !important',
            },
        },
        MuiDialog: {
            paperWidthSm: {
                maxWidth: '750px',
            },
        },
        MuiIconButton: {
            root: {
                color: '#fff',
            },
        },
        MuiButton: {
            textPrimary: {
                color: '#288690 !important',
            },
            textSecondary: {
                color: '#fff !important',
            },
            root: {
                color: '#288690',
            },
        },
    },
    palette: {
        primary: {
            main: '#288690',
        },
        secondary: {
            main: '#288690',
        },
    },
});

export default theme;