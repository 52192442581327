import * as React from 'react';
import {FC, useState} from 'react';
import {useSelector} from 'react-redux';
import {MenuItemLink} from 'react-admin';
import {
    AccountBalance,
    AccountBalanceWallet,
    AssignmentReturned,
    BorderColor,
    CallMade,
    ContactPhone,
    DoneAll,
    FontDownload,
    Group,
    ImportContacts,
    InsertComment,
    KeyboardArrowUp,
    LibraryBooks,
    ListAlt,
    LiveHelp,
    MenuBook,
    MonetizationOn,
    MoneyOff,
    QuestionAnswer,
    RecentActors,
    School,
    ShopTwo,
    Sms,
    Timer,
    VerifiedUser,
    NotificationsActive,
} from '@material-ui/icons';
import SubMenu from './SubMenu';
import {AppState} from './types';

type MenuName = 'tasks' | 'lessons' | 'tips' | 'transactions' | 'sms' | 'subscriptions' | 'administration';

interface Props {
    dense: boolean;
    onMenuClick: () => void;
}

const Menu: FC<Props> = ({ onMenuClick }) => {
    const [state, setState] = useState({
        lessons: false,
        tasks: false,
        tips: false,
        transactions: false,
        sms: false,
        subscriptions: false,
        administration: false,
    });
    const open = useSelector((state: AppState) => state.admin.ui.sidebarOpen);
    useSelector((state: AppState) => state.theme); // force rerender on theme change

    const handleToggle = (menu: MenuName) => {
        setState(state => ({ ...state, [menu]: !state[menu] }));
    };
    const dense = true;

    return (
        <div>
            {' '}
            <SubMenu
                handleToggle={() => handleToggle('lessons')}
                isOpen={state.lessons}
                sidebarIsOpen={open}
                name="Lessons"
                icon={<KeyboardArrowUp />}
                dense={dense}
            >
                <MenuItemLink
                    to={`/lesson-topics`}
                    primaryText={'Lesson topics'}
                    leftIcon={<School />}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                />
                <MenuItemLink
                    to={`/lessons`}
                    primaryText={'Lessons'}
                    leftIcon={<MenuBook />}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                />
                <MenuItemLink
                    to={`/lesson-materials`}
                    primaryText={'Lesson materials'}
                    leftIcon={<LibraryBooks />}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                />
                <MenuItemLink
                    to={`/lesson-questions`}
                    primaryText={'Lesson questions'}
                    leftIcon={<ImportContacts />}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                />
                <MenuItemLink
                    to={`/lesson-question-answers`}
                    primaryText={'Lesson question answers'}
                    leftIcon={<QuestionAnswer />}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                />
                <MenuItemLink
                    to={`/lesson-test-attempts`}
                    primaryText={'Lesson test attempts'}
                    leftIcon={<BorderColor />}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                />
                <MenuItemLink
                    to={`/lesson-progress-levels`}
                    primaryText={'Lesson progress levels'}
                    leftIcon={<CallMade />}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                />
            </SubMenu>
            <SubMenu
                handleToggle={() => handleToggle('tasks')}
                isOpen={state.tasks}
                sidebarIsOpen={open}
                name="Tasks"
                icon={<KeyboardArrowUp />}
                dense={dense}
            >
                <MenuItemLink
                    to={`/tasks`}
                    primaryText={'Tasks'}
                    leftIcon={<ListAlt />}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                />
                <MenuItemLink
                    to={`/recommended-tasks`}
                    primaryText={'Recommended tasks'}
                    leftIcon={<AssignmentReturned />}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                />
            </SubMenu>
            <SubMenu
                handleToggle={() => handleToggle('tips')}
                isOpen={state.tips}
                sidebarIsOpen={open}
                name="Tips"
                icon={<KeyboardArrowUp />}
                dense={dense}
            >
                <MenuItemLink
                    to={`/tips`}
                    primaryText={'Tips'}
                    leftIcon={<InsertComment />}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                />
                <MenuItemLink
                    to={`/tip-completions`}
                    primaryText={'Tip completions'}
                    leftIcon={<DoneAll />}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                />
            </SubMenu>
            <MenuItemLink
                to={`/faqs`}
                primaryText={'FAQs'}
                leftIcon={<LiveHelp />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
            />
            <MenuItemLink
                to={`/pages`}
                primaryText={'Pages'}
                leftIcon={<FontDownload />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
            />
            <MenuItemLink
                to={`/users`}
                primaryText={'Users'}
                leftIcon={<Group />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
            />
            <SubMenu
                handleToggle={() => handleToggle('transactions')}
                isOpen={state.transactions}
                sidebarIsOpen={open}
                name="Transactions"
                icon={<KeyboardArrowUp />}
                dense={dense}
            >
                <MenuItemLink
                    to={`/banking-transactions`}
                    primaryText={'Banking transactions'}
                    leftIcon={<AccountBalance />}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                />
                <MenuItemLink
                    to={`/wallet-transactions`}
                    primaryText={'Wallet transactions'}
                    leftIcon={<AccountBalanceWallet />}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                />
                <MenuItemLink
                    to={`/top-up-transactions`}
                    primaryText={'Top Up Transactions'}
                    leftIcon={<MonetizationOn />}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                />
            </SubMenu>
            <SubMenu
                handleToggle={() => handleToggle('sms')}
                isOpen={state.sms}
                sidebarIsOpen={open}
                name="SMS"
                icon={<KeyboardArrowUp />}
                dense={dense}
            >
                <MenuItemLink
                    to={`/sms`}
                    primaryText={'SMS'}
                    leftIcon={<Sms />}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                />
                <MenuItemLink
                    to={`/sms-allowed-numbers`}
                    primaryText={'SMS allowed numbers'}
                    leftIcon={<ContactPhone />}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                />
            </SubMenu>
            <SubMenu
                handleToggle={() => handleToggle('subscriptions')}
                isOpen={state.subscriptions}
                sidebarIsOpen={open}
                name="Subscriptions"
                icon={<KeyboardArrowUp />}
                dense={dense}
            >
                <MenuItemLink
                    to={`/subscription-durations`}
                    primaryText={'Subscription durations'}
                    leftIcon={<Timer />}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                />
                <MenuItemLink
                    to={`/subscriptions`}
                    primaryText={'Subscriptions'}
                    leftIcon={<ShopTwo />}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                />
                <MenuItemLink
                    to={`/discount-codes`}
                    primaryText={'Discount codes'}
                    leftIcon={<MoneyOff />}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                />
                <MenuItemLink
                    to={`/user-subscriptions`}
                    primaryText={'User subscriptions'}
                    leftIcon={<RecentActors />}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                />
                <MenuItemLink
                    to={`/taxes`}
                    primaryText={'Taxes'}
                    leftIcon={<MonetizationOn />}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                />
                <MenuItemLink
                    to={`/settings`}
                    primaryText={'Settings'}
                    leftIcon={<MonetizationOn />}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                />
            </SubMenu>
            <SubMenu
                handleToggle={() => handleToggle('administration')}
                isOpen={state.administration}
                sidebarIsOpen={open}
                name="Administration"
                icon={<KeyboardArrowUp />}
                dense={dense}
            >
                <MenuItemLink
                    to={`/admins`}
                    primaryText={'Admins'}
                    leftIcon={<VerifiedUser />}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                />
                <MenuItemLink
                    to={`/early-adopters`}
                    primaryText={'Early adopters'}
                    leftIcon={<Group />}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                />
                <MenuItemLink
                    to={`/notifications`}
                    primaryText={'Notifications'}
                    leftIcon={<NotificationsActive />}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                />
            </SubMenu>
            <MenuItemLink
                to={`/feedback`}
                primaryText={'Feedback'}
                leftIcon={<VerifiedUser />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
            />
        </div>
    );
};

export default Menu;
