import React from "react";
import { TextField } from 'react-admin';
import { FieldProps } from "../../../core/Types/common";

const ResourceIdField = (props: FieldProps) => {
    const { record, source } = props;

    if (record !== undefined && record.id !== undefined){
        let match;

        // Matches formats such as: /api/resource/123
        if ((match = (/\/(?<id>\d+)$/m).exec(record.id)) !== null) {
            record[source] = match.groups.id;
        }

        // Matches formats such as: /api/resource/uuid=705736ec-ff8f...;id=123
        if ((match = (/\bid=(?<id>\d+)\b/m).exec(record.id)) !== null) {
            record[source] = match.groups.id;
        }
    }

    return <TextField {...props} record={record}/>
}

ResourceIdField.defaultProps = { label: '#ID', source: 'originId', sortBy: 'id' }

export default ResourceIdField;
