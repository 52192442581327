import React from "react";
import {NumberInput, required, SelectArrayInput} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';

import DialogContent from "@material-ui/core/DialogContent";

export const selectArrayInput = (
    <SelectArrayInput optionText="title"/>
);

export const dialogContent = (
    <DialogContent>
        <RichTextInput source="title" validate={required()} fullWidth/>
        <RichTextInput source="description" validate={required()} fullWidth/>
        <RichTextInput source="submitButton" fullWidth/>
        <RichTextInput source="finishText" label="Additional description" fullWidth/>
        <NumberInput source="position" step={1} min={0} validate={required()} fullWidth/>
    </DialogContent>
);