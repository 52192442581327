import React from "react";
import { FieldProps } from "../../../core/Types/common";
import BooleanChip from "../../Element/Chip/BooleanChip";
import { TimerOff, Timer } from '@material-ui/icons';

const SubscriptionExpireStatusField = (props: FieldProps) => {
    const { record, source } = props;

    return <BooleanChip
        value={!record[source]}
        labelTrue="Active"
        labelFalse="Expired"
        iconFalse={<TimerOff />}
        iconTrue={<Timer />}
    />;
}

SubscriptionExpireStatusField.defaultProps = { label: "Status", source: 'expired' }

export default SubscriptionExpireStatusField;