import React from "react";
import { TextField } from 'react-admin';

const UuidField = (props) => (
    <TextField {...props}/>
)

UuidField.defaultProps = { label: "Uuid", source: "uuid" }

export default UuidField;
