import React from "react";
import {
    Create,
    NumberInput,
    SelectInput,
    SimpleForm,
    required
} from 'react-admin';
import {EuropeCountryChoices} from "../../../../core/Types/country";
import SettingCategories from "../categories";

export default (props) => (
    <Create {...props}>
        <SimpleForm>
            <SelectInput source="category" validate={required()} choices={SettingCategories} />
            <SelectInput source="country" validate={required()} choices={EuropeCountryChoices} />
            <NumberInput label="Value" source="value" min={0} validate={required()}/>
        </SimpleForm>
    </Create>
);