import { SessionUser } from "./SessionUser";
const storageKey = 'sessionUser';

export const userStorage = {
    setUser: (data: SessionUser | null) => {
        if (data) {
            sessionStorage.setItem(storageKey, JSON.stringify(data));
        } else {
            sessionStorage.removeItem(storageKey);
        }
    },
    getUser: (): SessionUser | null => {
        const data = sessionStorage.getItem(storageKey);
        return data ? JSON.parse(data) : null;
    },
    hasUser: (): boolean => {
        return !!sessionStorage.getItem(storageKey);
    },
    removeUser: (): void => {
        sessionStorage.removeItem(storageKey);
    }
}