import React from "react";
import TimestampField from "./TimestampField";

class LastLoginField extends TimestampField
{
    static defaultProps = {
        label: 'Last login',
        source: 'lastLogin',
    }
}

export default LastLoginField;