import React from "react";
import {
    Create,
    NumberInput,
    SimpleForm,
    required
} from 'react-admin';

export default (props) => {
    return (
        <Create {...props}>
            <SimpleForm>
                <NumberInput source="months" step={1} min={1} validate={required()} />
            </SimpleForm>
        </Create>
    );
}
