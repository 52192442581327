import React from "react";
import { FieldProps } from "../../../core/Types/common";
import { Chip, Typography } from '@material-ui/core';

interface Props extends FieldProps {
    component?: 'text' | 'chip';
    fallbackLabel?: string
    size?: 'small' | 'medium'
}

class EnumTypeField extends React.Component<Props>
{
    render() {
        const { record, source, component, size } = this.props;
        const value = record[source];
        const label = this.getLabel(value);

        if (component === 'text') {
            return <Typography variant="inherit" component="span">{label}</Typography>;
        }

        return (
            <Chip
                color={this.getColor(value)}
                icon={this.getIcon(value)}
                size={size || 'small'}
                label={label}
            />
        );
    }

    getLabel(value) {
        const { fallbackLabel } = this.props;
        const map = this.getEnumMap();

        if (value in map) {
            return map[value];
        }

        return !!fallbackLabel ? fallbackLabel : `Unknown enum value (${value})`;
    }

    getColor(value) {
        const map = this.getColorMap();
        return value in map ? map[value] : 'default';
    }

    getIcon(value) {
        const map = this.getIconMap();
        return value in map ? map[value] : null;
    }

    /**
     * Return enum values mapped to labels
     * Example:
     * {
     *     0: 'Zero value',
     *     1; 'Value one'
     * }
     */
    getEnumMap(): object {
        return {};
    }

    getColorMap(): object {
        return {};
    }

    getIconMap(): object {
        return {};
    }
}

export default EnumTypeField;
