import React from "react";
import {Edit, SimpleForm} from 'react-admin';

export const TaskEdit = (props) => {
    return (
        <Edit {...props}>
            <SimpleForm>
            </SimpleForm>
        </Edit>
    );
}
