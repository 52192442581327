import { combineReducers } from 'redux'

// const update = (todoId, isDone) => (dispatch) =>
//     new Promise(function(resolve, reject) {
//         dispatch({
//             type: 'SET_SAVING',
//             saving: true
//         });
//         // Function is expected to return a promise
//         callUpdateApi(todoId, isDone).then(updatedTodo => {
//             dispatch({
//                 type: 'SET_SAVING',
//                 saving: false
//             });
//
//             resolve(updatedTodo);
//         }).catch(error => {
//             // TBD: Handle errors for Redux
//
//             reject(error);
//         })
//     });

const pagination = (state = {}, { type, payload }) => {
    console.log({type, payload});
    switch (type) {
        case 'CUSTOM_FETCH_SUCCESS':
            console.log(payload);
            return state;
        case 'FETCH_PAGINATION':
            return { ...state };
        default:
            return state
    }
}

export default combineReducers({
    pagination
})