import React from "react";
import {
    BooleanField,
    Datagrid,
    EditButton,
    NumberField,
    ReferenceField,
    ShowButton,
    TextField,
} from 'react-admin';
import ResourceIdField from "../../../Field/Common/ResourceIdField";
import CreatedAtField from "../../../Field/Common/CreatedAtField";
import UpdatedAtField from "../../../Field/Common/UpdatedAtField";
import ListConfirmDelete from "../../../Element/List/ListConfirmDelete";

export const LessonsList = (props) => (
    <ListConfirmDelete {...props}>
        <Datagrid>
            <ResourceIdField record />
            <CreatedAtField record />
            <UpdatedAtField record />
            <TextField source="title"/>
            <ReferenceField label="Lesson topic" source="topic" reference="lesson-topics">
                <TextField source="title" />
            </ReferenceField>
            <NumberField source="position"/>
            <BooleanField source="published"/>
            <ShowButton />
            <EditButton />
        </Datagrid>
    </ListConfirmDelete>
);