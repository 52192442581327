import React from "react";
import { FieldProps } from "../../../core/Types/common";
import BooleanChip from "../../Element/Chip/BooleanChip";

const UserSubscriptionStatusField = (props: FieldProps) => {
    const { record, source } = props;
    return <BooleanChip value={!!record[source]} labelTrue="Yes" labelFalse="No"/>;
}

UserSubscriptionStatusField.defaultProps = { label: "Subscribed", source: 'subscriptionStatus' }

export default UserSubscriptionStatusField;
