import React from "react";
import {
    Create,
    ImageInput,
    ImageField,
    SimpleForm,
    TextInput,
    required,
    NullableBooleanInput,
    NumberInput
} from 'react-admin';
import RichTextInput from "ra-input-rich-text";

export const TipCreate = (props) => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="title" validate={required()} />
            <NumberInput source="position" step={1} min={0} />
            <RichTextInput multiline source="description" validate={required()} />
            <RichTextInput multiline source="text" validate={required()} />
            <NullableBooleanInput label="For subscribers only" source="forSubscribersOnly" validate={required()} />
            <ImageInput source="encodedImage" label="Image" accept="image/*">
                <ImageField source="image" title="Image" />
            </ImageInput>
        </SimpleForm>
    </Create>
);
