import React from 'react';
import config from "./config";
import Layout from "./layout/Layout";
import Theme from "./layout/Theme";
import {
    HydraAdmin,
    ResourceGuesser,
} from '@api-platform/admin';
import { Resource } from 'react-admin';

import { authProvider } from "./auth/authProvider";
import resources from "./resources";
import dataProvider from "./dataProvider";
import reducers from "./store/reducers";

const entrypoint = config.api.entrypoint.root;

export default () => (
    <HydraAdmin
        theme={Theme}
        layout={Layout}
        entrypoint={entrypoint}
        dataProvider={dataProvider}
        authProvider={authProvider}
        customReducers={reducers}
    >
        {resources.map((props, key) => {
            return !!props.hidden
                ? <Resource key={key} {...props}/>
                : <ResourceGuesser key={key} {...props}/>
        })}
        <div id="snackbars"> </div>
    </HydraAdmin>
);
