import React, {Fragment} from "react";
import {BulkDeleteButton, Pagination, BulkExportButton, List} from 'react-admin';

const PostBulkActionButtons = props => (
    <Fragment>
        <BulkExportButton {...props} />
        <BulkDeleteButton {...props} undoable={false}/>
    </Fragment>
);
const PostPagination = props => (
    <Pagination rowsPerPageOptions={[25, 100, 250]} {...props} />
)

export default (props) => (
    <List
      {...props}
      bulkActionButtons={<PostBulkActionButtons />}
      pagination={<PostPagination />}
      perPage={25}
    />
);
