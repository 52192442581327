import React from "react";
import EnumTypeField from "../Common/EnumTypeField";
import { CheckCircle, Timelapse, HighlightOff } from '@material-ui/icons';
import {
    STATUS_APPROVING,
    STATUS_COMPLETED,
    STATUS_EVALUATING,
    STATUS_IN_PROGRESS,
    STATUS_REJECTED
} from "../../../core/Types/StatusCodes";

class TransactionStatusField extends EnumTypeField
{
    static defaultProps = {
        source: 'status',
        label: 'Status'
    };

    getEnumMap(): object {
        return {
            [STATUS_APPROVING]: 'Approving',
            [STATUS_COMPLETED]: 'Completed',
            [STATUS_REJECTED]: 'Rejected',
            [STATUS_EVALUATING]: 'Evaluating',
            [STATUS_IN_PROGRESS]: 'In progress',
        }
    }

    getColorMap(): object {
        return {
            [STATUS_COMPLETED]: 'primary'
        }
    }

    getIconMap(): object {
        return {
            [STATUS_APPROVING]: <Timelapse />,
            [STATUS_COMPLETED]: <CheckCircle />,
            [STATUS_REJECTED]: <HighlightOff />,
            [STATUS_EVALUATING]: <Timelapse />,
            [STATUS_IN_PROGRESS]: <Timelapse />,
        }
    }
}

export default TransactionStatusField;
