import config from '../config';
import Cookies from 'universal-cookie';
import moment from 'moment';
import { pickBy, identity } from 'lodash';
import { decodeToken } from "./utils";

const cookieConfig = config.tokenCookie;
const cookie = new Cookies();

const buildOptions = (expires?) => {
    return pickBy({
        expires,
        domain: cookieConfig.domain || undefined,
        path: cookieConfig.path || undefined,
        secure: config.env !== 'development',
    }, identity);
}

export const tokenStorage = {
    setToken: (token: string) => {
        const { exp: serverExpires } = decodeToken(token);
        const localExpires = !isNaN(cookieConfig.lifetime)
            ? moment().add(cookieConfig.lifetime, 'seconds').unix()
            : undefined;

        const expires = localExpires !== undefined
            ? moment.unix(Math.min(localExpires, serverExpires)).toDate()
            : moment.unix(serverExpires).toDate();

        cookie.set(cookieConfig.name, token, buildOptions(expires));
    },
    getToken: (): string|null => {
        return cookie.get(cookieConfig.name);
    },
    hasToken: (): boolean => {
        return !!cookie.get(cookieConfig.name);
    },
    removeToken: (): void => {
        cookie.remove(cookieConfig.name, buildOptions());
    },
}