import React from "react";
import {
    AutocompleteInput,
    Create,
    ReferenceInput,
    SelectInput,
    SimpleForm,
    required
} from 'react-admin';

export const TipCompletionCreate = (props) => (
    <Create {...props}>
        <SimpleForm>
            <ReferenceInput
                source="tip"
                reference="tips"
                perPage={50}
                sort={{ field: 'title', order: 'ASC' }}
                validate={required()}>
                <AutocompleteInput optionText="title" optionValue="id" />
            </ReferenceInput>
            <ReferenceInput
                source="user"
                reference="users"
                perPage={50}
                sort={{ field: 'name', order: 'ASC' }}
                validate={required()}>
                <AutocompleteInput optionText="name" optionValue="id" />
            </ReferenceInput>
        </SimpleForm>
    </Create>
);