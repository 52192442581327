import {
    CATEGORY_OTHER,
    CATEGORY_PRIVACY_POLICY,
    CATEGORY_SECURITY,
    CATEGORY_LESSON_PROPOSAL,
    CATEGORY_TERMS_AND_CONDITIONS
} from "../../../core/Types/page";

export const PageCategoryChoices = [
    { id: CATEGORY_PRIVACY_POLICY, name: CATEGORY_PRIVACY_POLICY },
    { id: CATEGORY_TERMS_AND_CONDITIONS, name: CATEGORY_TERMS_AND_CONDITIONS },
    { id: CATEGORY_SECURITY,  name: CATEGORY_SECURITY },
    { id: CATEGORY_LESSON_PROPOSAL,  name: CATEGORY_LESSON_PROPOSAL },
    { id: CATEGORY_OTHER,  name: CATEGORY_OTHER },
];