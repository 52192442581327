import { get } from 'lodash';
import moment from 'moment';
import jwt_decode from 'jwt-decode';
import { NullableString } from "../core/Types/common";
import config from "../config";
import { tokenStorage } from "./tokenStorage";
import { AdminUserTokenType } from "../core/Types/userToken";

export const buildHeaderToken = (token: NullableString): string => {
    return token ? `${config.api.token.valuePrefix}${token}` : '';
}

export const buildApiHeaders = (): Record<string, string> => {
    const token = tokenStorage.getToken();
    return {
        [config.api.token.headerName]: token ? `${config.api.token.valuePrefix}${token}` : ''
    }
}

export const formatApiUrl = (resource, id = null): string => {
    return [config.api.entrypoint.root, resource, id].join('/');
}

export const extractErrorMessage = (error) => {
    let message = get(error, 'response.data.message');

    if (message) {
        return message;
    }

    return error;
}

export const decodeToken = (token: NullableString): AdminUserTokenType => {
    return token ? jwt_decode(token) : {};
}

export const validateToken = (token: NullableString): boolean => {
    const decoded = decodeToken(token);
    return !!decoded && decoded.exp > moment().unix();
}