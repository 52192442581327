import React from "react";
import {
    WALLET_TRANSACTION_TYPE_DEPOSIT,
    WALLET_TRANSACTION_TYPE_GOAL,
    WALLET_TRANSACTION_TYPE_INTEREST,
    WALLET_TRANSACTION_TYPE_INTERNAL,
    WALLET_TRANSACTION_TYPE_POCKET_MONEY,
    WALLET_TRANSACTION_TYPE_REQUEST,
    WALLET_TRANSACTION_TYPE_LEVEL_REWARD,
    WALLET_TRANSACTION_TYPE_INSUFFICIENT_FUNDS,
    WALLET_TRANSACTION_TYPE_WITHDRAW_ATM,
    WALLET_TRANSACTION_TYPE_TOP_UP,
    WALLET_TRANSACTION_TYPE_TRANSACTION_FEE,
    WALLET_TRANSACTION_TYPE_WITHDRAW_IBAN
} from "../../../core/Types/wallet";
import EnumTypeField from "../Common/EnumTypeField";

class WalletTransactionTypeField extends EnumTypeField
{
    static defaultProps = {
        label: 'Type',
        source: 'type',
        component: 'text'
    }

    getEnumMap(): object {
        return {
            [WALLET_TRANSACTION_TYPE_INTERNAL]: 'Internal',
            [WALLET_TRANSACTION_TYPE_INTEREST]: 'Interest',
            [WALLET_TRANSACTION_TYPE_REQUEST]: 'Request',
            [WALLET_TRANSACTION_TYPE_POCKET_MONEY]: 'Pocket-money',
            [WALLET_TRANSACTION_TYPE_GOAL]: 'Savings-goal',
            [WALLET_TRANSACTION_TYPE_DEPOSIT]: 'Investment-deposit',
            [WALLET_TRANSACTION_TYPE_LEVEL_REWARD]: 'Level-reward',
            [WALLET_TRANSACTION_TYPE_INSUFFICIENT_FUNDS]: 'Insufficient-funds',
            [WALLET_TRANSACTION_TYPE_WITHDRAW_ATM]: 'Withdraw-ATM',
            [WALLET_TRANSACTION_TYPE_TOP_UP]: 'Top-up',
            [WALLET_TRANSACTION_TYPE_TRANSACTION_FEE]: 'Transaction-fee',
            [WALLET_TRANSACTION_TYPE_WITHDRAW_IBAN]: 'Withdraw-IBAN',
        };
    }
}

export default WalletTransactionTypeField;
