import * as React from "react";
import {NumberField, ReferenceField, RichTextField, Show, SimpleShowLayout, TextField} from 'react-admin';
import CreatedAtField from "../../../Field/Common/CreatedAtField";
import UpdatedAtField from "../../../Field/Common/UpdatedAtField";

export const LessonMaterialShow = (props) => (
    <Show {...props}>
        <SimpleShowLayout>
            <ReferenceField label="Lesson" source="lesson" reference="lessons">
                <TextField source="title" />
            </ReferenceField>
            <RichTextField source="title" />
            <RichTextField source="description" />
            <RichTextField source="submitButton" />
            <RichTextField source="finishText" label="Additional description" />
            <NumberField source="position" />
            <CreatedAtField addLabel />
            <UpdatedAtField addLabel />
        </SimpleShowLayout>
    </Show>
);
