import React from "react";
import EnumTypeField from "../Common/EnumTypeField";

class UserTypeField extends EnumTypeField
{
    static defaultProps = {
        label: "Type",
        source: 'isParent',
        component: 'text',
    }

    getLabel(value): any {
        return value ? 'Parent' : 'Child';
    }
}

export default UserTypeField;
