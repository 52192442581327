import React from "react";
import { TextField } from 'react-admin';

const BeneficiaryNameField = (props) => (
    <TextField {...props}/>
)

BeneficiaryNameField.defaultProps = { label: "Beneficiary name", source: "beneficiaryName" }

export default BeneficiaryNameField;
