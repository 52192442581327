import * as React from "react";
import {
    NumberField,
    Show,
    SelectField,
    SimpleShowLayout
} from 'react-admin';
import CreatedAtField from "../../../Field/Common/CreatedAtField";
import UpdatedAtField from "../../../Field/Common/UpdatedAtField";
import {EuropeCountryChoices} from "../../../../core/Types/country";
import TaxCategories from "../categories";

export default (props) => (
    <Show {...props}>
        <SimpleShowLayout>
            <SelectField source="category" choices={TaxCategories} />
            <SelectField source="country" choices={EuropeCountryChoices} />
            <NumberField label="Price" source="price" />
            <CreatedAtField addLabel />
            <UpdatedAtField addLabel />
        </SimpleShowLayout>
    </Show>
);
