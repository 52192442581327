import * as React from 'react';
import {AppBar} from 'react-admin';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';
import {userStorage} from "../core/Model/SessionUser/userStorage";
import UpdateTranslationsButton from "../../src/component/Element/Button/UpdateTranslationsButton";

const useStyles = makeStyles({
    title: {
        flex: 1,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
    spacer: {
        flex: 1,
    },
    username: {
        fontFamily: "inherit",
        float: 'right',
    },
});

let user = userStorage.getUser()
const username = user ? user.name : ''

const CustomAppBar = (props: any) => {
    const classes = useStyles();
    return (
        <AppBar {...props} elevation={1}>
            <Typography
                variant="h6"
                color="inherit"
                className={classes.title}
                id="react-admin-title"
            />
            <Typography
                variant="h6"
                color="inherit"
                className={classes.spacer}>
                <small className={classes.username}>{username}</small>
            </Typography>
            <UpdateTranslationsButton/>
        </AppBar>
    );
};

export default CustomAppBar;