import React from "react";
import {
    BooleanInput,
    Edit,
    ImageInput,
    ImageField,
    SimpleForm,
    TextInput,
    required
} from "react-admin";
import RichTextInput from "ra-input-rich-text";

export const LessonTopicEdit = (props) => {
    return (
        <Edit {...props}>
            <SimpleForm>
                <TextInput source="title" validate={required()} />
                <RichTextInput source="description" validate={required()} />
                <ImageField source="image" title="Image" />
                <ImageInput source="encodedImage" label="Change image" accept="image/*">
                    <ImageField source="image" title="Image" />
                </ImageInput>
                <BooleanInput source="removeImage" />
            </SimpleForm>
        </Edit>
    );
}
