import React from "react";
import { FieldProps } from "../../../core/Types/common";
import BooleanChip from "../../Element/Chip/BooleanChip";

const RelatedUserField = (props: FieldProps) => {
    const { record, source } = props;

    if (record === undefined) {
        return <BooleanChip value={false} labelFalse="false" />;
    }

    return <BooleanChip value={!!record[source]} labelTrue={record["name"]} labelFalse={record["name"]}/>;
}

RelatedUserField.defaultProps = { source: 'isActive' }

export default RelatedUserField;
