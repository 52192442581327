import React from 'react';
import { Datagrid, NumberField, ReferenceArrayField, ReferenceField, Tab, TextField, FunctionField } from 'react-admin';
import TimestampField from "../../../../../Field/Common/TimestampField";
import useStyles from "../../../../../../styles";
import YesNoField from "../../../../../Field/Common/YesNoField";
import ResourceIdField from "../../../../../Field/Common/ResourceIdField";
import SubscriptionExpireStatusField from "../../../../../Field/UserSubscription/SubscriptionExpireStatusField";

const parentSubscriptions = (record, classes) => {
    return (
        <ReferenceArrayField reference="user-subscriptions" record={record} source="childrenSubscriptions">
            <Datagrid>
                <ResourceIdField cellclassname={classes.narrow}/>
                <ReferenceField label="Subscriber" source="user" reference="users" cellclassname={classes.narrow}>
                    <TextField source="name"/>
                </ReferenceField>
                <ReferenceField label="Subscription" source="subscription" reference="subscriptions" cellclassname={classes.fw200}>
                    <TextField source="title"/>
                </ReferenceField>
                <NumberField label="Price" source="finalPrice" cellclassname={classes.narrow}/>
                <ReferenceField label="Discount code" source="discountCode" reference="discount-codes" cellclassname={classes.fw125}>
                    <TextField source="title"/>
                </ReferenceField>
                <FunctionField label="Paid" render={item => <YesNoField record={item} source="paid"/>} cellclassname={classes.narrow}/>
                <TimestampField source="activeSince" cellclassname={classes.narrow}/>
                <TimestampField source="endDate" cellclassname={classes.narrow}/>
                <FunctionField label="Status" render={item => <SubscriptionExpireStatusField record={item}/>} cellclassname={classes.narrow}/>
            </Datagrid>
        </ReferenceArrayField>
    )
}

const childrenSubscriptions = (record, classes) => {
    return (
        <ReferenceArrayField reference="user-subscriptions" record={record} source="subscriptions">
            <Datagrid>
                <ResourceIdField cellclassname={classes.narrow}/>
                <ReferenceField label="Subscription" source="subscription" reference="subscriptions" cellclassname={classes.fw200}>
                    <TextField source="title"/>
                </ReferenceField>
                <NumberField label="Price" source="finalPrice" cellclassname={classes.narrow}/>
                <ReferenceField label="Discount code" source="discountCode" reference="discount-codes" cellclassname={classes.fw125}>
                    <TextField source="title"/>
                </ReferenceField>
                <FunctionField label="Paid" render={item => <YesNoField record={item} source="paid"/>} cellclassname={classes.narrow}/>
                <TimestampField source="activeSince" cellclassname={classes.narrow}/>
                <TimestampField source="endDate" cellclassname={classes.narrow}/>
                <FunctionField label="Status" render={item => <SubscriptionExpireStatusField record={item}/>} cellclassname={classes.narrow}/>
            </Datagrid>
        </ReferenceArrayField>
    )
}

const SubscriptionTab = (props) => {
    const { record } = props;
    const classes = useStyles();
    return (
        <Tab label="Subscription" path="user-subscription" {...props}>
            {record.isParent ? parentSubscriptions(record, classes) : childrenSubscriptions(record, classes)}
        </Tab>
    )
};

export default SubscriptionTab;
