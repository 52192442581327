import React from "react";
import {
    DateTimeInput,
    Edit,
    NumberInput,
    SelectInput,
    SimpleForm,
    TextInput,
    required
} from 'react-admin';
import DiscountCodeTypes from '../types'

export default (props) => {
    return (
        <Edit {...props}>
            <SimpleForm>
                <TextInput multiline source="title" validate={required()} />
                <TextInput multiline source="code" validate={required()} />
                <SelectInput source="type" validate={required()} choices={DiscountCodeTypes} />
                <NumberInput source="discountSize" step={0.01} min={0.01} />
                <NumberInput source="duration" />
                <DateTimeInput source="validityBegin" />
                <DateTimeInput source="validityEnd" />
            </SimpleForm>
        </Edit>
    );
}
