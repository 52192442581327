import React from "react";
import TimestampField from "./TimestampField";

class UpdatedAtField extends TimestampField
{
    static defaultProps = {
        label: 'Updated at',
        source: 'updatedAt',
    }
}

export default UpdatedAtField;