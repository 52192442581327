import React from "react";
import { Chip } from "@material-ui/core";
import { FieldProps } from "../../../core/Types/common";
import {
    SMS_CONFIRM_CODE,
    INVITE_FOR_RELATION,
    INVITE_NEW_USER
} from "../../../core/Types/sms";

interface Props extends FieldProps{
    component?: 'text' | 'chip'
}

const getTypeLabel = (type: number): string => {
    switch (type) {
        case SMS_CONFIRM_CODE: return 'Confirm code'
        case INVITE_FOR_RELATION: return 'Invite for relation'
        case INVITE_NEW_USER: return 'Invite new user'
        default: return ''
    }
}

const SmsTypeField = (props: Props) => {
    const {
        record: { type },
        component
    } = props;

    if (component && component === 'text') {
        return <div>{ getTypeLabel(type) }</div>;
    }

    return <Chip size="small" label={getTypeLabel(type)} />;
}

SmsTypeField.defaultProps = { label: "Type" }

export default SmsTypeField;