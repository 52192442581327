import * as React from "react";
import { Show, SimpleShowLayout, TextField } from 'react-admin';
import CreatedAtField from "../../../Field/Common/CreatedAtField";
import MoneyAmountField from "../../../Field/Wallet/MoneyAmountField";
import TransactionStatusField from "../../../Field/Wallet/TransactionStatusField";
import WalletTransactionTypeField from "../../../Field/Wallet/WalletTransactionTypeField";
import UpdatedAtField from "../../../Field/Common/UpdatedAtField";
import SenderNameField from "../../../Field/Wallet/SenderNameField";
import BeneficiaryNameField from "../../../Field/Wallet/BeneficiaryNameField";
import UuidField from "../../../Field/Wallet/UuidField";
import CommentField from "../../../Field/Wallet/CommentField";

export const WalletTransactionShow = (props) => (
    <Show {...props}>
        <SimpleShowLayout>
            <TransactionStatusField addLabel component="text"/>
            <MoneyAmountField addLabel/>
            <SenderNameField addLabel/>
            <TextField source="senderPhone" addLabel/>
            <BeneficiaryNameField addLabel/>
            <TextField source="beneficiaryPhone" addLabel/>
            <CommentField addLabel/>
            <WalletTransactionTypeField addLabel component="text"/>
            <UuidField addLabel/>
            <CreatedAtField addLabel/>
            <UpdatedAtField addLabel/>
        </SimpleShowLayout>
    </Show>
);
