import React from "react";
import {
    Edit,
    SelectInput,
    SimpleForm,
    TextInput,
    required
} from 'react-admin';
import RichTextInput from "ra-input-rich-text";
import {PageCategoryChoices} from "../categories";
import {CATEGORY_OTHER} from "../../../../core/Types/page";

export const PageEdit = (props) => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput multiline source="title" validate={required()} />
            <SelectInput
                source="category"
                choices={PageCategoryChoices}
                validate={required()}
                defaultValue={CATEGORY_OTHER}
            />
            <RichTextInput multiline source="description" validate={required()} />
        </SimpleForm>
    </Edit>
);