import React from "react";
import { TextField } from 'react-admin';

const SenderNameField = (props) => (
    <TextField {...props}/>
)

SenderNameField.defaultProps = { label: "Sender name", source: "senderName" }

export default SenderNameField;
