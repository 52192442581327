import React from "react";
import {
    Edit,
    SimpleForm,
    TextInput,
    TextField,
    NumberInput,
    PasswordInput,
    RadioButtonGroupInput,
} from 'react-admin';
import { RoleChoices } from "../../Admin/types";
import { ROLE_ADMIN, ROLE_SUPER_ADMIN } from "../../../../core/Types/roles";
import CreatedAtField from "../../../Field/Common/CreatedAtField";
import UpdatedAtField from "../../../Field/Common/UpdatedAtField";
import TransactionStatusField from "../../../Field/Wallet/TransactionStatusField";
import BankingTransactionTypeField from "../../../Field/Wallet/BankingTransactionTypeField";
import UuidField from "../../../Field/Wallet/UuidField";
import { ChoiceInterface, EnumTypeChoiceInput } from "../../../Input/Common/EnumTypeChoiceInput";
import { STATUS_APPROVING, STATUS_COMPLETED } from "../../../../core/Types/StatusCodes";

class StatusChoiceInput extends EnumTypeChoiceInput
{
    getChoices(): ChoiceInterface[] {
        return [
            { id: STATUS_APPROVING, name: 'Approving' },
            { id: STATUS_COMPLETED, name: 'Completed' }
        ]
    }
}

export const BankingTransactionEdit = (props) => (
    <Edit {...props}>
        <SimpleForm redirect={false}>
            <BankingTransactionTypeField addLabel component="text"/>
            <StatusChoiceInput source="status"/>
            <TextInput source="accountNumber"/>
            <NumberInput source="amount"/>
            <TextInput source="name"/>
            <TextInput source="surname"/>
            <TextInput source="comment"/>
            <TransactionStatusField addLabel component="text"/>
            <UuidField addLabel/>
            <TextField source="externalId" addLabel/>
            <CreatedAtField addLabel/>
            <UpdatedAtField addLabel/>
        </SimpleForm>
    </Edit>
);
