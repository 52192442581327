import * as React from 'react';
import ReactDOM from 'react-dom';
import {fetchUtils} from 'react-admin';
import IconButton from '@material-ui/core/IconButton';
import {Translate} from '@material-ui/icons';
import Tooltip from '@material-ui/core/Tooltip';
import config from "../../../../src/config";
import {tokenStorage} from '../../../auth/tokenStorage';
import Snackbar from '@material-ui/core/Snackbar';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';

const SimpleSnackbar = (message: string, id: string) => {
    let open = true;
    const handleClose = () => {
        ReactDOM.render(
            <div> </div>,
            document.querySelector(`#${id}`)
        );
    };

    return (
        <Snackbar
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            open={open}
            action={
                <React.Fragment>
                    <Button color="secondary" size="small">
                        {message}
                    </Button>
                    <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
                        <CloseIcon fontSize="small"/>
                    </IconButton>
                </React.Fragment>
            }
        />
    );
}

const generateSnackbar = (message: string, language: string) => {
    let snackbarDiv = document.getElementById('snackbars');
    if (!snackbarDiv) {
        return;
    }
    const divId = `snackbar-${language}`
    const d = document.createElement("div")
    d.id = divId
    snackbarDiv.appendChild(d)
    ReactDOM.render(
        SimpleSnackbar(message, divId),
        document.getElementById(divId)
    );
}

const httpClient = fetchUtils.fetchJson;
const entrypoint = config.api.entrypoint.root;

const updateTranslations = () => {
    config.translationLanguages.map((language) => {
            const data = {language: language};
            const headers = new Headers({
                'accept': 'application/ld+json',
                'Content-Type': 'application/ld+json',
                'Authorization': `Bearer ${tokenStorage.getToken()}`,
            });
            httpClient(`${entrypoint}/translations`, {
                method: 'POST',
                body: JSON.stringify(data),
                headers: headers,
            }).then(response => {
                generateSnackbar(
                    `Translations '${language}' updated successfully`,
                    language
                );
            }).catch((error) => {
                generateSnackbar(
                    `Translations '${language}' cannot be updated`,
                    language
                );
                return error;
            });
        })
}

const UpdateTranslationsButton = () => {
    return <IconButton onClick={updateTranslations}>
        <Tooltip title="Update translations">
            <Translate/>
        </Tooltip>
    </IconButton>;
};

export default UpdateTranslationsButton;