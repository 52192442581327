import React from "react";
import {Edit, SimpleForm, required, TextInput} from 'react-admin';

export default (props) => {
    return (
        <Edit {...props}>
            <SimpleForm>
                <TextInput source="phone" validate={required()} />
                <TextInput source="comment" />
            </SimpleForm>
        </Edit>
    );
}
