const COUNTRY_DEFAULT        = { id: 'DEFAULT', name: 'Default' }

// ----- EU countries
const COUNTRY_AUSTRIA        = { id: 'AUT', name: 'Austria' }
const COUNTRY_BELGIUM        = { id: 'BEL', name: 'Belgium' }
const COUNTRY_BULGARIA       = { id: 'BGR', name: 'Bulgaria' }
const COUNTRY_CROATIA        = { id: 'HRV', name: 'Croatia' }
const COUNTRY_CYPRUS         = { id: 'CYP', name: 'Cyprus' }
const COUNTRY_CZECH_REPUBLIC = { id: 'CZE', name: 'Czech Republic' }
const COUNTRY_DENMARK        = { id: 'DNK', name: 'Denmark' }
const COUNTRY_ESTONIA        = { id: 'EST', name: 'Estonia' }
const COUNTRY_FINLAND        = { id: 'FIN', name: 'Finland' }
const COUNTRY_FRANCE         = { id: 'FRA', name: 'France' }
const COUNTRY_GERMANY        = { id: 'DEU', name: 'Germany' }
const COUNTRY_GREECE         = { id: 'GRC', name: 'Greece' }
const COUNTRY_HUNGARY        = { id: 'HUN', name: 'Hungary' }
const COUNTRY_IRELAND        = { id: 'IRL', name: 'Ireland' }
const COUNTRY_ITALY          = { id: 'ITA', name: 'Italy' }
const COUNTRY_LATVIA         = { id: 'LVA', name: 'Latvia' }
const COUNTRY_LITHUANIA      = { id: 'LTU', name: 'Lithuania' }
const COUNTRY_LUXEMBOURG     = { id: 'LUX', name: 'Luxembourg' }
const COUNTRY_MALTA          = { id: 'MLT', name: 'Malta' }
const COUNTRY_NETHERLANDS    = { id: 'NLD', name: 'Netherlands' }
const COUNTRY_POLAND         = { id: 'POL', name: 'Poland' }
const COUNTRY_PORTUGAL       = { id: 'PRT', name: 'Portugal' }
const COUNTRY_ROMANIA        = { id: 'ROU', name: 'Romania' }
const COUNTRY_SLOVAKIA       = { id: 'SVK', name: 'Slovakia' }
const COUNTRY_SLOVENIA       = { id: 'SVN', name: 'Slovenia' }
const COUNTRY_SPAIN          = { id: 'ESP', name: 'Spain' }
const COUNTRY_SWEDEN         = { id: 'SWE', name: 'Sweden' }
// ----- EU countries

// ----- Non-EU countries
const COUNTRY_ALBANIA                = { id: 'ALB', name: 'Albania' }
const COUNTRY_ANDORRA                = { id: 'AND', name: 'Andorra' }
const COUNTRY_ARMENIA                = { id: 'ARM', name: 'Armenia' }
const COUNTRY_BELARUS                = { id: 'BLR', name: 'Belarus' }
const COUNTRY_BOSNIA_AND_HERZEGOVINA = { id: 'BIH', name: 'Bosnia and Herzegovina' }
const COUNTRY_FAROE_ISLANDS          = { id: 'FRO', name: 'Faroe Islands' }
const COUNTRY_GEORGIA                = { id: 'GEO', name: 'Georgia' }
const COUNTRY_GIBRALTAR              = { id: 'GIB', name: 'Gibraltar' }
const COUNTRY_ICELAND                = { id: 'ISL', name: 'Iceland' }
const COUNTRY_ISLE_OF_MAN            = { id: 'IMN', name: 'Isle of Man' }
const COUNTRY_KOSOVO                 = { id: 'XKX', name: 'Kosovo' }
const COUNTRY_LIECHTENSTEIN          = { id: 'LIE', name: 'Liechtenstein' }
const COUNTRY_MACEDONIA              = { id: 'MKD', name: 'Macedonia' }
const COUNTRY_MOLDOVA                = { id: 'MDA', name: 'Moldova' }
const COUNTRY_MONACO                 = { id: 'MCO', name: 'Monaco' }
const COUNTRY_MONTENEGRO             = { id: 'MNE', name: 'Montenegro' }
const COUNTRY_NORWAY                 = { id: 'NOR', name: 'Norway' }
const COUNTRY_RUSSIAN_FEDERATION     = { id: 'RUS', name: 'Russian Federation' }
const COUNTRY_SAN_MARINO             = { id: 'SMR', name: 'San Marino' }
const COUNTRY_SERBIA                 = { id: 'SRB', name: 'Serbia' }
const COUNTRY_SWITZERLAND            = { id: 'CHE', name: 'Switzerland' }
const COUNTRY_TURKEY                 = { id: 'TUR', name: 'Turkey' }
const COUNTRY_UKRAINE                = { id: 'UKR', name: 'Ukraine' }
const COUNTRY_UNITED_KINGDOM         = { id: 'GBR', name: 'United Kingdom' }
const COUNTRY_VATICAN_CITY_STATE     = { id: 'VAT', name: 'Vatican City State' }
// ----- Non-EU countries

export const EuropeCountryChoices = [
    COUNTRY_DEFAULT,
    COUNTRY_ALBANIA,
    COUNTRY_ANDORRA,
    COUNTRY_ARMENIA,
    COUNTRY_AUSTRIA,
    COUNTRY_BELARUS,
    COUNTRY_BELGIUM,
    COUNTRY_BOSNIA_AND_HERZEGOVINA,
    COUNTRY_BULGARIA,
    COUNTRY_CROATIA,
    COUNTRY_CYPRUS,
    COUNTRY_CZECH_REPUBLIC,
    COUNTRY_DENMARK,
    COUNTRY_ESTONIA,
    COUNTRY_FAROE_ISLANDS,
    COUNTRY_FINLAND,
    COUNTRY_FRANCE,
    COUNTRY_GEORGIA,
    COUNTRY_GERMANY,
    COUNTRY_GIBRALTAR,
    COUNTRY_GREECE,
    COUNTRY_HUNGARY,
    COUNTRY_ICELAND,
    COUNTRY_IRELAND,
    COUNTRY_ISLE_OF_MAN,
    COUNTRY_ITALY,
    COUNTRY_KOSOVO,
    COUNTRY_LATVIA,
    COUNTRY_LIECHTENSTEIN,
    COUNTRY_LITHUANIA,
    COUNTRY_LUXEMBOURG,
    COUNTRY_MACEDONIA,
    COUNTRY_MALTA,
    COUNTRY_MOLDOVA,
    COUNTRY_MONACO,
    COUNTRY_MONTENEGRO,
    COUNTRY_NETHERLANDS,
    COUNTRY_NORWAY,
    COUNTRY_POLAND,
    COUNTRY_PORTUGAL,
    COUNTRY_ROMANIA,
    COUNTRY_RUSSIAN_FEDERATION,
    COUNTRY_SAN_MARINO,
    COUNTRY_SERBIA,
    COUNTRY_SLOVAKIA,
    COUNTRY_SLOVENIA,
    COUNTRY_SPAIN,
    COUNTRY_SWEDEN,
    COUNTRY_SWITZERLAND,
    COUNTRY_TURKEY,
    COUNTRY_UKRAINE,
    COUNTRY_UNITED_KINGDOM,
    COUNTRY_VATICAN_CITY_STATE
]