import React from "react";
import {
    Datagrid,
    EditButton,
    ReferenceManyField,
    SingleFieldList,
    ShowButton,
    TextField,
} from 'react-admin';
import ResourceIdField from "../../../Field/Common/ResourceIdField";
import CreatedAtField from "../../../Field/Common/CreatedAtField";
import UpdatedAtField from "../../../Field/Common/UpdatedAtField";
import ListConfirmDelete from "../../../Element/List/ListConfirmDelete";

export const LessonProgressLevelsList = (props) => (
    <ListConfirmDelete {...props}>
        <Datagrid>
            <ResourceIdField record />
            <CreatedAtField record />
            <UpdatedAtField record />
            <TextField source="title" />
            <TextField source="testsToPassAmount" />
            <ReferenceManyField source="predecessor" reference="lesson-progress-levels" target="predecessor">
                <SingleFieldList>
                    <TextField source="title" />
                </SingleFieldList>
            </ReferenceManyField >
            <ShowButton />
            <EditButton />
        </Datagrid>
    </ListConfirmDelete>
);