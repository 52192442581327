import * as React from "react";
import {
    ImageField,
    ReferenceField,
    Show,
    SimpleShowLayout,
    TextField
} from 'react-admin';
import CreatedAtField from "../../../Field/Common/CreatedAtField";
import UpdatedAtField from "../../../Field/Common/UpdatedAtField";

export const LessonProgressLevelShow = (props) => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField source="title" />
            <TextField source="testsToPassAmount" />
            <ReferenceField label="Predecessor" source="predecessor" reference="lesson-progress-levels">
                <TextField source="title" />
            </ReferenceField>
            <ImageField source="image" title="Image" />
            <CreatedAtField addLabel />
            <UpdatedAtField addLabel />
        </SimpleShowLayout>
    </Show>
);
