import React from "react";
import {
    BooleanInput,
    Edit,
    ImageField,
    ImageInput,
    SimpleForm,
    TextInput,
    required,
    NullableBooleanInput,
    NumberInput
} from 'react-admin';
import RichTextInput from "ra-input-rich-text";

export const TipEdit = (props) => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput source="title" validate={required()} />
            <NumberInput source="position" step={1} min={0} validate={required()} />
            <RichTextInput multiline source="description" validate={required()} />
            <RichTextInput multiline source="text" validate={required()} />
            <NullableBooleanInput label="For subscribers only" source="forSubscribersOnly" validate={required()} />
            <ImageField source="image" title="Image" />
            <ImageInput source="encodedImage" label="Change image" accept="image/*">
                <ImageField source="image" title="Image" />
            </ImageInput>
            <BooleanInput source="removeImage" />
        </SimpleForm>
    </Edit>
);
