import React from "react";
import useStyles from "../../../../styles";
import config from "../../../../config";

import { useQuery, Loading, Error } from 'react-admin';

const TopUpBalance = () => {
    const classes = useStyles();
    const { data, loading, error } = useQuery({
        type: 'getOne',
        payload: { id: '/api/walletto-account-balance' },
    });

    let balance;

    if (loading) return <Loading />;
    if (error) return <Error />;

    if (data) {
        data['hydra:member'].forEach((entry) => {
            if (entry.id === 'topUp') {
                balance = (entry.balance * config.money.factor);
            }
        })
    }

    return balance
        ? (
            <div className={classes.tableHeading}>Top up balance: {balance}</div>
        ) : null;
};

export const TopUpTransactionsHeading = (props) => {
    return (
        <TopUpBalance/>
    );
};
