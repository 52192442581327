import * as React from "react";
import {
    BooleanField,
    Datagrid,
    EditButton,
    NumberField,
    ReferenceArrayField,
    ReferenceField,
    RichTextField,
    Show,
    SimpleShowLayout,
    TextField
} from 'react-admin';
import CreatedAtField from "../../../Field/Common/CreatedAtField";
import UpdatedAtField from "../../../Field/Common/UpdatedAtField";

export const LessonQuestionShow = (props) => (
    <Show {...props}>
        <SimpleShowLayout>
            <ReferenceField label="Lesson" source="lesson" reference="lessons">
                <TextField source="title" />
            </ReferenceField>
            <RichTextField source="question" />
            <RichTextField source="wrongAnswerText" />
            <RichTextField source="correctAnswerText" />
            <NumberField source="position" />
            <ReferenceArrayField
                reference="lesson-question-answers"
                source="answers"
                label="Answers">
                <Datagrid>
                    <RichTextField source="text" />
                    <BooleanField source="correct" />
                    <NumberField source="position" />
                    <EditButton />
                </Datagrid>
            </ReferenceArrayField >
            <CreatedAtField addLabel />
            <UpdatedAtField addLabel />
        </SimpleShowLayout>
    </Show>
);
