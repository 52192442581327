import React from "react";
import {
    Datagrid,
    NumberField,
    ReferenceField,
    ShowButton,
    TextField,
} from 'react-admin';
import ResourceIdField from "../../../Field/Common/ResourceIdField";
import CreatedAtField from "../../../Field/Common/CreatedAtField";
import UpdatedAtField from "../../../Field/Common/UpdatedAtField";
import useStyles from "../../../../styles";
import TimestampField from "../../../Field/Common/TimestampField";
import ListConfirmDelete from "../../../Element/List/ListConfirmDelete";
import YesNoField from "../../../Field/Common/YesNoField";
import SubscriptionExpireStatusField from "../../../Field/UserSubscription/SubscriptionExpireStatusField";

export default (props) => {
    const classes = useStyles();
    return (
        <ListConfirmDelete {...props}>
            <Datagrid>
                <ResourceIdField cellclassname={classes.narrow} />
                <CreatedAtField />
                <UpdatedAtField />
                <ReferenceField label="User" source="user" reference="users">
                    <TextField source="name" />
                </ReferenceField>
                <ReferenceField label="Subscription" source="subscription" reference="subscriptions">
                    <TextField source="title" />
                </ReferenceField>
                <ReferenceField label="Discount code" source="discountCode" reference="discount-codes">
                    <TextField source="title" />
                </ReferenceField>
                <NumberField source="finalPrice"/>
                <YesNoField source="paid"/>
                <TimestampField source="activeSince" />
                <TimestampField source="endDate"/>
                <SubscriptionExpireStatusField source="expired"/>
                <ShowButton />
            </Datagrid>
        </ListConfirmDelete>
    );
};