import React from "react";
import { DateTimeInput } from 'react-admin';

const validateTime = (v) => {
    if (!!v && (new Date(v)) < (new Date())) {
        return 'Sending time cannot be earlier than now';
    }

    return '';
}

const SendingTimeInput = (props) => {
    return <DateTimeInput defaultValue={null} {...props} validate={validateTime}/>
}

SendingTimeInput.defaultProps = {
    source: 'sendingTime'
}

export default SendingTimeInput;