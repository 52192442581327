import * as React from "react";
import {
    ImageField,
    NumberField,
    ReferenceField,
    Show,
    SimpleShowLayout,
    TextField
} from 'react-admin';
import CreatedAtField from "../../../Field/Common/CreatedAtField";
import UpdatedAtField from "../../../Field/Common/UpdatedAtField";
import TaskStatusField from "../../../Field/Task/TaskStatusField";

export const TaskShow = (props) => (
    <Show {...props}>
        <SimpleShowLayout>
            <ReferenceField label="Owner" source="owner" reference="users">
                <TextField source="name" />
            </ReferenceField>
            <ReferenceField label="Assignee" source="assignee" reference="users">
                <TextField source="name" />
            </ReferenceField>
            <ReferenceField label="Recommended task" source="recommendedTask" reference="recommended-tasks">
                <TextField source="title" />
            </ReferenceField>
            <TextField source="title" label="Title" />
            <NumberField source="estimatedPay" />
            <TaskStatusField component="text" addLabel />
            <TextField source="description" />
            <ImageField source="image" title="Image" />
            <CreatedAtField addLabel />
            <UpdatedAtField addLabel />
        </SimpleShowLayout>
    </Show>
);
