import React from "react";
import {
    Datagrid,
    EditButton,
    NumberField,
    ReferenceField,
    RichTextField,
    ShowButton,
    TextField,
} from 'react-admin';
import ResourceIdField from "../../../Field/Common/ResourceIdField";
import CreatedAtField from "../../../Field/Common/CreatedAtField";
import UpdatedAtField from "../../../Field/Common/UpdatedAtField";
import ListConfirmDelete from "../../../Element/List/ListConfirmDelete";

export const LessonQuestionsList = (props) => (
    <ListConfirmDelete {...props}>
        <Datagrid>
            <ResourceIdField record />
            <CreatedAtField record />
            <UpdatedAtField record />
            <ReferenceField label="Lesson" source="lesson" reference="lessons">
                <TextField source="title" />
            </ReferenceField>
            <RichTextField source="question"/>
            <NumberField source="position"/>
            <ShowButton />
            <EditButton />
        </Datagrid>
    </ListConfirmDelete>
);